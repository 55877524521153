<template>
  <div class="message-gift">
    <img class="message-gift-img" :src="img" />
    <div class="message-gift-text">
      <div class="message-gift-name">送你{{ name }}</div>
      <div class="message-gift-num">x {{ num }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    img: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    num: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style scoped lang="scss">
.message-gift {
  display: flex;
  background: #ffffff;
  padding: 12px;
  border-radius: 12px;
  border: 2px solid #eef2f2;
}
.message-gift-img {
  display: block;
  width: 56px;
  height: 56px;
}
.message-gift-text {
  flex: auto;
  display: flex;
  flex-direction: column;
  margin-left: 6px;
}
.message-gift-name {
  font-weight: 500;
  font-size: 14px;
  color: #1f2626;
  line-height: 20px;
}
.message-gift-num {
  margin-top: 8px;
  font-size: 12px;
  color: #9fa7a7;
  line-height: 16px;
}
</style>
