<template>
  <Transition name="fade">
    <div v-if="visible" class="snapchat-image-viewer">
      <i v-if="loading" class="text-3xl text-white el-icon-loading"></i>
      <img v-else :src="url" />
      <i class="el-icon-circle-close close" @click="handleClose"></i>

      <div v-if="showCountdown" class="countdown">
        倒计时{{ current }}s后销毁
      </div>
    </div>
  </Transition>
</template>

<script>
import { loadImage } from '@/utils/file';

export default {
  props: {
    url: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      duration: 10,
      current: 0,
      timer: null,
      showCountdown: false,
      visible: false,
      loading: false,
    };
  },
  beforeDestroy() {
    this.stop();
  },
  methods: {
    open(showCountdown) {
      this.visible = true;
      this.showCountdown = showCountdown;
      this.current = this.duration;
      this.loading = true;
      loadImage(this.url)
        .then(() => {
          if (this.visible) {
            this.start();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    start() {
      this.stop();
      this.timer = setInterval(() => {
        if (--this.current < 0) {
          this.timeout();
        }
      }, 1000);
    },
    stop() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
    timeout() {
      this.stop();
      this.visible = false;
    },
    handleClose() {
      this.timeout();
    },
  },
};
</script>

<style scoped lang="scss">
.snapchat-image-viewer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2147483647;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 30px;
    color: #fff;
    cursor: pointer;
  }
  .countdown {
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    text-shadow: 0 0 5px #000;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
