<template>
  <div class="message-dice-question">
    <div class="message-dice-question-sum">点数和={{ sum }}</div>
    <div class="message-dice-question-text">
      <img src="@/images/home/game/ic_game_question.png" />
      {{ question }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sum: {
      type: Number,
      default: undefined,
    },
    question: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped lang="scss">
.message-dice-question {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 343px;
  height: 65px;
  margin: 0 auto;
  background: url("@/images/home/game/img_game_touzi.png") center center /
    contain no-repeat;
}
.message-dice-question-sum {
  font-size: 12px;
  color: #1f2626;
  line-height: 17px;
}
.message-dice-question-text {
  display: flex;
  align-items: center;
  margin-top: 6px;
  font-weight: 500;
  font-size: 13px;
  color: #1f2626;
  line-height: 18px;

  img {
    position: relative;
    top: -1px;
    margin-right: 2px;
  }
}
</style>
