<template>
  <div
    v-if="visible"
    ref="contextMenu"
    class="shadow-lg context-menu"
    :style="positionStyle"
  >
    <Menu :list="list" @select="handleClick" />
  </div>
</template>

<script>
import Menu from '@/components/Menu';

export default {
  components: {
    Menu,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      visible: false,
      top: 0,
      left: 0,
      mousedownHandler: (event) => {
        if (this.visible && !this.$refs.contextMenu.contains(event.target)) {
          this.visible = false;
        }
      },
    };
  },
  computed: {
    positionStyle() {
      return {
        top: this.top + 'px',
        left: this.left + 'px',
      };
    },
  },
  mounted() {
    window.addEventListener('mousedown', this.mousedownHandler);
  },
  beforeDestroy() {
    window.removeEventListener('mousedown', this.mousedownHandler);
  },
  methods: {
    handleClick(item) {
      this.$emit('select', item);
      this.visible = false;
    },
    show(event) {
      event.preventDefault();
      this.top = event.clientY;
      this.left = event.clientX;
      this.visible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.context-menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10001;
}
</style>
