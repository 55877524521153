import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import ElementUI from 'element-ui';
import './element-variables.scss'
import 'tailwindcss/tailwind.css';
import i18n from './lang'
import '@/icons';
Vue.use(ElementUI); 

import moment from 'moment';
moment.locale('zh-cn');

Vue.filter('calendar', function (second) {
  return moment(second * 1000).calendar();
})

Vue.config.productionTip = false

Vue.prototype.$bus = new Vue()

new Vue({
  store,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
