<template>
  <div class="message-local-media gap-x-2" :class="`flow-${flow}`">
    <SnapchatCard :flow="flow" @click="handlePreview" />
    <Upload ref="uploadRef" @success="handleSuccess" />
    <SnapchatImageViewer
      :visible.sync="imageViewerVisible"
      :url="url"
      @timeout="imageViewerVisible = false"
    />
    <SnapchatVideoViewer
      :visible.sync="videoViewerVisible"
      :url="url"
      @timeout="videoViewerVisible = false"
    />
  </div>
</template>

<script>
import Upload from '@/components/Upload.vue';
import SnapchatCard from '@/components/SnapchatCard.vue';
import SnapchatImageViewer from '@/components/SnapchatImageViewer.vue';
import SnapchatVideoViewer from '@/components/SnapchatVideoViewer.vue';
import { sendMedia } from '@/api/chat';

export default {
  components: {
    Upload,
    SnapchatCard,
    SnapchatImageViewer,
    SnapchatVideoViewer,
  },
  props: {
    url: {
      type: String,
      default: '',
    },
    metaType: {
      type: String,
      default: '',
    },
    file: {
      type: File,
      required: true,
    },
    width: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number,
      default: 0,
    },
    flow: {
      type: String,
      default: '',
    },
    receiveId: {
      type: Number,
      required: true,
    },
    messageId: {
      type: String,
      required: true,
    },
    robotId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      imageViewerVisible: false,
      videoViewerVisible: false,
    };
  },
  mounted() {
    this.$refs.uploadRef.send(this.file);
  },
  beforeDestroy() {
    if (this.url && this.url.indexOf('blob:') === 0) {
      URL.revokeObjectURL(this.url);
    }
  },
  methods: {
    handleSuccess(event) {
      this.$bus.$emit(this.messageId, {
        type: 'status',
        status: 'loading',
      });
      sendMedia({
        user_id: this.robotId,
        receive_id: this.receiveId,
        chat_type: 'c2c',
        local_msg_id: this.messageId,
        media_type: this.metaType,
        media_url: event.url,
        media_info: event.extra,
        type: 2,
      })
        .then(() => {
          this.$bus.$emit(this.messageId, {
            type: 'status',
            status: 'success',
          });

          this.$emit('success');
        })
        .catch(() => {
          this.$bus.$emit(this.messageId, {
            type: 'status',
            status: 'error',
          });
        });
    },
    handlePreview() {
      if (this.metaType === 'image') {
        this.imageViewerVisible = true;
      } else if (this.metaType === 'video') {
        this.videoViewerVisible = true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.message-local-media {
  display: flex;
  align-items: center;

  &.flow-out {
    flex-direction: row-reverse;
  }
}
</style>
