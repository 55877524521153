<template>
  <el-popover placement="top" trigger="click" ref="popover" @show="handleShow">
    <div class="gift-tips">
      <i class="el-icon-question"></i>
      确定赠送该礼物，一经赠送不能进行退还
    </div>
    <div class="gift-list">
      <div
        v-for="item in giftList"
        :key="item.gift_id"
        class="gift-item"
        :class="{ selected: currentGift?.gift_id === item.gift_id }"
        @click="handleGiftSelect(item)"
      >
        <img class="gift-item-img" :src="item.img" />
        <div class="truncate gift-item-title">{{ item.title }}</div>
        <div class="gift-item-num">可赠送 {{ item.giftable_num }}</div>
      </div>
    </div>
    <div class="gift-footer">
      <el-input-number
        v-model="num"
        :precision="0"
        :step="1"
        :min="1"
        size="mini"
        controls-position="right"
      />
      <el-button
        type="primary"
        size="mini"
        :disabled="!currentGift"
        :loading="sendLoading"
        @click="handleSend"
      >
        赠送
      </el-button>
    </div>
    <template #reference>
      <slot></slot>
    </template>
  </el-popover>
</template>

<script>
import { getGiftBackpack, giveGift } from '@/api/chat';

export default {
  props: {
    friendId: {
      type: Number,
      required: true,
    },
    robotId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      getLoading: false,
      sendLoading: false,
      giftList: [],
      currentGift: null,
      num: 1,
    };
  },
  methods: {
    doClose() {
      this.$refs.popover.doClose();
    },
    doShow() {
      this.$refs.popover.doShow();
    },
    handleShow() {
      this.getGiftBackpackData();
    },
    getGiftBackpackData() {
      if (this.getLoading) {
        return;
      }
      this.getLoading = true;
      getGiftBackpack({
        user_id: this.robotId,
        pagesize: 100,
      })
        .then((res) => {
          this.giftList = res.data.list;
        })
        .finally(() => {
          this.getLoading = false;
        });
    },
    handleGiftSelect(item) {
      this.currentGift = item;
    },
    handleSend() {
      if (this.num > this.currentGift.giftable_num) {
        this.$message.warning('礼物可赠送数量不足');
        return;
      }
      this.sendLoading = true;
      giveGift({
        user_id: this.robotId,
        gift_id: this.currentGift.gift_id,
        num: this.num,
        source: 6,
        source_id: this.robotId,
        receiver_user_id: this.friendId,
      })
        .then(() => {
          this.doClose();
          setTimeout(() => {
            this.sendLoading = false;
          }, 500)
        })
        .catch(() => {
          this.sendLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.gift-tips {
  margin-bottom: 12px;
  font-size: 12px;
  line-height: 16px;
  color: #9fa7a7;
}
.gift-list {
  display: flex;
  flex-wrap: wrap;
  width: 440px;
  height: 226px;
  column-gap: 8px;
  row-gap: 2px;
  overflow-y: auto;
  overflow-x: hidden;
}
.gift-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 104px;
  padding: 5px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color .15s;

  &.selected {
    background: var(--color-primary-100);
  }
}
.gift-item-img {
  display: flex;
  width: 48px;
  height: 48px;
}
.gift-item-title {
  width: 100%;
  margin-top: 4px;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
}
.gift-item-num {
  margin-top: 2px;
  font-size: 10px;
  color: #9fa7a7;
  line-height: 14px;
}
.gift-footer {
  display: flex;
  justify-content: flex-end;
  column-gap: 8px;
  margin-top: 12px;
}
</style>
