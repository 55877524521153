import chalk from 'chalk';

// 将传入的秒数转换为小时、分钟和秒
export function formatTimeBySeconds(time) {
  let hours = Math.floor(time / 3600);
  let minutes = Math.floor((time % 3600) / 60);
  let seconds = time % 60;

  // 格式化小时、分钟和秒
  let result = '';
  if (hours > 0) {
    result += hours < 10 ? '0' + hours : hours;
    result += ':';
  }
  result += minutes < 10 ? '0' + minutes : minutes;
  result += ':';
  result += seconds < 10 ? '0' + seconds : seconds;

  return result;
}

export function getLabelByValue(
  value,
  options,
  labelName = 'label',
  valueName = 'value',
) {
  return options.find((option) => option[valueName] === value)?.[labelName];
}

export function log(...args) {
  if (process.env.NODE_ENV === 'development') {
    console.log(chalk.bgBlack.white('chat'), ...args);
  }
}

export function random(min, max, decimal = 0) {
  return Number((Math.random() * (max - min) + min).toFixed(decimal));
}

// 将秒数格式化成冒号分割的时间
export function formatVideoDuration(second) {
  return (
    Math.floor(second / 60)
      .toString()
      .padStart(2, '0') +
    ':' +
    Math.round(second % 60)
      .toString()
      .padStart(2, '0')
  );
}
