<template>
  <div class="message-dice-invate" @click="handleClick">
    <img
      class="message-dice-invate-img"
      src="@/images/home/game/ic_game_fqtouzi.png"
    />
    <div class="message-dice-invate-text">
      <div class="message-dice-invate-title">快来和我一起摇骰子吧！</div>
      <div class="message-dice-invate-description">查看本局游戏规则</div>
    </div>

    <DiceQuestionDialog
      :visible.sync="visible"
      :robotId="robotId"
      :friendId="friendId"
      :gameId="gameId"
      @start="visible = false"
    />
  </div>
</template>

<script>
import DiceQuestionDialog from '@/components/DiceQuestionDialog';

export default {
  components: {
    DiceQuestionDialog,
  },
  props: {
    gameId: {
      type: [Number, String],
      required: true,
    },
    robotId: {
      type: Number,
      required: true,
    },
    friendId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    handleClick() {
      this.visible = true;
    },
  },
};
</script>

<style scoped lang="scss">
.message-dice-invate {
  display: flex;
  padding: 12px;
  background: #ffffff;
  border-radius: 12px;
  border: 2px solid #eef2f2;
  cursor: pointer;
}
.message-dice-invate-img {
  display: block;
  width: 48px;
  height: 48px;
}
.message-dice-invate-text {
  flex: auto;
  margin-left: 8px;
}
.message-dice-invate-title {
  font-weight: 500;
  font-size: 14px;
  color: #1f2626;
  line-height: 20px;
}
.message-dice-invate-description {
  margin-top: 8px;
  font-size: 12px;
  color: #9fa7a7;
  line-height: 16px;
}
</style>
