<template>
  <div class="message-snapchat-media">
    <SnapchatCard :flow="flow" :destroyed="!canView" @click="handlePreview" />
    <SnapchatImageViewer ref="imageViewer" :url="innerUrl" />
    <SnapchatVideoViewer ref="videoViewer" :url="innerUrl" />
  </div>
</template>

<script>
import { getMediaInfo, readMedia } from '@/api/chat';
import SnapchatCard from '@/components/SnapchatCard.vue';
import SnapchatImageViewer from '@/components/SnapchatImageViewer.vue';
import SnapchatVideoViewer from '@/components/SnapchatVideoViewer.vue';

export default {
  components: {
    SnapchatCard,
    SnapchatImageViewer,
    SnapchatVideoViewer,
  },
  props: {
    url: {
      type: String,
      default: '',
    },
    metaType: {
      type: String,
      default: '',
    },
    recordId: {
      type: [Number, String],
      required: true,
    },
    flow: {
      type: String,
      default: '',
    },
    destroyed: {
      type: Boolean,
      default: false,
    },
    robotId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      viewed: this.destroyed,
      showCountdown: this.flow === 'in',
      innerUrl: this.url
    };
  },
  computed: {
    canView() {
      return this.flow === 'out' || !this.viewed;
    },
  },
  methods: {
    async handlePreview() {
      if (!this.canView) {
        return;
      }

      if (this.flow === 'out' && !this.innerUrl) {
        await getMediaInfo({
          user_id: this.robotId,
          record_id: this.recordId,
        }).then((res) => {
          this.innerUrl = res.data.media_url
        })
      }

      if (this.flow === 'in') {
        readMedia({
          user_id: this.robotId,
          record_id: this.recordId,
        });
      }
      this.viewed = true;

      if (this.metaType === 'image') {
        this.$refs.imageViewer.open(this.showCountdown);
      } else if (this.metaType === 'video') {
        this.$refs.videoViewer.open(this.showCountdown);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.message-snapchat-media {
  display: flex;
}
</style>
