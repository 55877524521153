<template>
  <div
    class="message-cycles-invate"
    @click="handleClick"
    :class="{ 'can-activate': canActivate }"
  >
    <img
      class="message-cycles-invate-img"
      src="@/images/home/game/ic_game_fqcaiquan.png"
    />
    <div class="message-cycles-invate-text">
      <div class="message-cycles-invate-title">快来和我一起猜拳吧！</div>
      <div class="message-cycles-invate-description">
        输了要玩真心话大冒险哦
      </div>
    </div>
  </div>
</template>

<script>
import { getCyclesGameInfo, startCyclesGame } from '@/api/chat';

export default {
  props: {
    gameId: {
      type: [Number, String],
      required: true,
    },
    robotId: {
      type: Number,
      required: true,
    },
    friendId: {
      type: Number,
      required: true,
    },
    isInitiator: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      detailLoading: false,
      startLoading: false,
      started: false,
    };
  },
  computed: {
    canActivate() {
      return !this.isInitiator && !this.started;
    },
  },
  methods: {
    handleClick() {
      if (this.isInitiator || this.detailLoading || this.startLoading || this.started) {
        return;
      }
      this.detailLoading = true;
      getCyclesGameInfo({
        game_id: this.gameId,
      })
        .then(({ data }) => {
          // 1=创建，2=开始，3=结束，4=过期
          if ([2, 3, 4].includes(data.status)) {
            return;
          }
          if (data.friend_id === this.robotId) {
            this.startLoading = true;
            startCyclesGame({
              game_id: this.gameId,
              robot_id: this.robotId,
            }).then(() => {
              this.started = true
            }).finally(() => {
              this.startLoading = false;
            });
          }
        })
        .finally(() => {
          this.detailLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.message-cycles-invate {
  display: flex;
  padding: 12px;
  background: #ffffff;
  border-radius: 12px;
  border: 2px solid #eef2f2;

  &.can-activate {
    cursor: pointer;
  }
}
.message-cycles-invate-img {
  display: block;
  width: 48px;
  height: 48px;
}
.message-cycles-invate-text {
  flex: auto;
  margin-left: 8px;
}
.message-cycles-invate-title {
  font-weight: 500;
  font-size: 14px;
  color: #1f2626;
  line-height: 20px;
}
.message-cycles-invate-description {
  margin-top: 8px;
  font-size: 12px;
  color: #9fa7a7;
  line-height: 16px;
}
</style>
