<template>
  <el-image
    :style="imageStyle"
    :src="url"
    fit="contain"
    :preview-src-list="[url]"
  />
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      default: '',
    },
    width: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      maxWidth: 150,
      maxHeight: 300,
    };
  },
  computed: {
    imageStyle() {
      let width = this.width;
      let height = this.height;
      if (this.width / this.height > this.maxWidth / this.maxHeight) {
        width = this.maxWidth;
        height = (this.height * this.maxWidth) / this.width;
      } else {
        height = this.maxHeight;
        width = (this.width * this.maxHeight) / this.height;
      }

      return {
        width: width + 'px',
        height: height + 'px',
        maxWidth: this.maxWidth + 'px',
        maxHeight: this.maxHeight + 'px',
      };
    },
  },
};
</script>
