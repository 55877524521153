<template>
  <div class="search">
    <el-popover
      placement="bottom-start"
      trigger="manual"
      class="flex-1"
      popper-class="p-0"
      :value="visible"
      @show="handlePopoverShow"
    >
      <div class="search-list-popover" ref="popover">
        <div
          v-if="filteredConversationList.length > 0"
          class="search-list-wrapper"
        >
          <SearchList
            :conversationList="filteredConversationList"
            @select="handleSelect"
          />
        </div>
        <div v-else class="search-empty">无数据</div>
      </div>
      <template #reference>
        <el-input
          v-model="keyword"
          class="search-input"
          size="mini"
          placeholder="输入昵称、皮雀ID"
          prefix-icon="el-icon-search"
          ref="input"
          clearable
          @focus="handleInputFocus"
        />
      </template>
    </el-popover>
    <SearchFilterPopper
      :conversationList="conversationList"
      @select="handleSelect"
    >
      <div class="search-btn">
        <svg-icon icon-class="filter" />
      </div>
    </SearchFilterPopper>
  </div>
</template>

<script>
import SearchFilterPopper from '@/components/SearchFilterPopper.vue';
import SearchList from '@/components/SearchList.vue';
import { throttle } from 'lodash-es';

export default {
  components: {
    SearchFilterPopper,
    SearchList,
  },
  props: {
    conversationList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      visible: false,
      keyword: '',
      throttledSearch: throttle(() => {
        return this.search();
      }, 500),
      filteredConversationList: [],
      mousedownHandler: (event) => {
        if (
          this.visible &&
          !this.$refs.popover?.contains(event.target) &&
          !this.$refs.input?.$el.contains(event.target)
        ) {
          this.visible = false;
        }
      },
    };
  },
  watch: {
    keyword() {
      this.throttledSearch();
    },
  },
  mounted() {
    window.addEventListener('mousedown', this.mousedownHandler);
  },
  beforeDestroy() {
    window.removeEventListener('mousedown', this.mousedownHandler);
  },
  methods: {
    doClose() {
      this.visible = false;
    },
    doShow() {
      this.visible = true;
    },
    search() {
      if (!this.keyword) {
        this.filteredConversationList = [];
        return;
      }
      this.filteredConversationList = this.conversationList.filter(
        (conversation) => {
          const chatData = conversation.chatData;
          if (chatData.nickname.includes(this.keyword)) {
            return true;
          }
          if (String(chatData.pic_id).includes(this.keyword)) {
            return true;
          }
        },
      );
    },
    handleInputFocus() {
      this.visible = true;
    },
    handleSelect(conversation) {
      this.$emit('select', conversation);
      this.visible = false;
    },
    handlePopoverShow() {
      this.throttledSearch();
    },
  },
};
</script>

<style scoped lang="scss">
.search {
  display: flex;
  margin: 10px;
  cursor: pointer;
}
.search-input {
  ::v-deep input {
    background: #ededed;
    border: none;
  }
}
.search-list-popover {
  width: 280px;
  padding: 10px;
}
.search-list-wrapper {
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}
.search-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.search-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  margin-left: 10px;
}
</style>
