import request from '@/utils/request';
// 聊天相关接口

// 获取关键字选项列表
export function getOption(params) {
  return request({
    url: '/admin/system/keyword/getOption',
    method: 'get',
    params,
  });
}