import { render, staticRenderFns } from "./MessageGift.vue?vue&type=template&id=6f7d8964&scoped=true&"
import script from "./MessageGift.vue?vue&type=script&lang=js&"
export * from "./MessageGift.vue?vue&type=script&lang=js&"
import style0 from "./MessageGift.vue?vue&type=style&index=0&id=6f7d8964&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f7d8964",
  null
  
)

export default component.exports