<template>
  <div class="chat-send-btn">
    <div class="chat-send-btn-tips">
      {{ $t('chat.huanhang') }}
    </div>
    <el-button
      type="primary"
      size="medium"
      :disabled="disabled"
      @click="$emit('click')"
    >
      {{ $t('chat.fasong') }}
    </el-button>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    }
  }
};
</script>

<style scoped lang="scss">
.chat-send-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
}
.chat-send-btn-tips {
  font-size: 12px;
  font-weight: 400;
  color: #8f8f96;
  line-height: 21px;
  margin-right: 12px;
}
</style>
