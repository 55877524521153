<template>
  <div class="message-local-media gap-x-2" :class="`flow-${flow}`">
    <LimitImage
      v-if="metaType === 'image'"
      :url="url"
      :width="width"
      :height="height"
    />
    <LimitVideo v-else-if="metaType === 'video'" :url="url" />
    <Upload ref="uploadRef" @success="handleSuccess" />
  </div>
</template>

<script>
import LimitVideo from '@/components/LimitVideo.vue';
import LimitImage from '@/components/LimitImage.vue';
import Upload from '@/components/Upload.vue';
import { sendMedia } from '@/api/chat';

export default {
  components: {
    LimitVideo,
    LimitImage,
    Upload,
  },
  props: {
    url: {
      type: String,
      default: '',
    },
    metaType: {
      type: String,
      default: '',
    },
    file: {
      type: File,
      required: true,
    },
    width: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number,
      default: 0,
    },
    flow: {
      type: String,
      default: '',
    },
    receiveId: {
      type: Number,
      required: true,
    },
    messageId: {
      type: String,
      required: true,
    },
    robotId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {};
  },
  mounted() {
    this.$refs.uploadRef.send(this.file);
  },
  beforeDestroy() {
    if (this.url && this.url.indexOf('blob:') === 0) {
      URL.revokeObjectURL(this.url);
    }
  },
  methods: {
    handleSuccess(event) {
      this.$bus.$emit(this.messageId, {
        type: 'status',
        status: 'loading',
      });
      sendMedia({
        user_id: this.robotId,
        receive_id: this.receiveId,
        chat_type: 'c2c',
        local_msg_id: this.messageId,
        media_type: this.metaType,
        media_url: event.url,
        media_info: event.extra,
        type: 1,
      })
        .then(() => {
          this.$bus.$emit(this.messageId, {
            type: 'status',
            status: 'success',
          });

          this.$emit('success');
        })
        .catch(() => {
          this.$bus.$emit(this.messageId, {
            type: 'status',
            status: 'error',
          });
        });
    },
  },
};
</script>

<style scoped lang="scss">
.message-local-media {
  display: flex;
  align-items: center;

  &.flow-out {
    flex-direction: row-reverse;
  }
}
</style>
