<template>
  <div v-if="uploadFile.status !== 'success'" class="upload">
    <i v-if="uploadFile.status === 'loading'" class="el-icon-loading"></i>
    <div v-else-if="uploadFile.status === 'error'">
      <el-button size="mini" type="text" @click="reUpload()">
        <div class="flex items-center">
          <i class="mr-1 text-base leading-tight el-icon-refresh-right"></i>
          重试
        </div>
      </el-button>
    </div>
  </div>
</template>

<script>
import { getFileType } from '@/utils/file';
import CryptoJS from 'crypto-js';

export default {
  data() {
    return {
      uploadFile: {
        status: 'ready',
        name: '',
        type: '',
        size: 0,
        percent: 0,
        url: '',
        previewUrl: '',
        raw: null,
        fetchOptions: null,
        extra: null,
        sign_salt: '',
      },
    };
  },

  created() {
    this.sign_salt = localStorage.getItem('sign_salt');
  },

  beforeDestroy() {
    if (
      this.uploadFile.previewUrl &&
      this.uploadFile.previewUrl.indexOf('blob:') === 0
    ) {
      URL.revokeObjectURL(this.uploadFile.previewUrl);
    }
  },

  methods: {
    send(file) {
      this.uploadFile = {
        status: 'ready',
        name: file.name,
        type: getFileType(file),
        size: file.size,
        percent: 0,
        url: '',
        previewUrl: URL.createObjectURL(file),
        raw: file,
        fetchOptions: null,
        extra: null,
      };
      this.upload(this.uploadFile);
    },
    upload(uploadFile) {
      const formData = new FormData();
      const file_type = getFileType(uploadFile.raw);
      const moduleName = 'chat';
      const timestamp = ~~(Date.now() / 1000);

      const signContent = `file_type=${file_type}&module=${moduleName}&${this.sign_salt}`;

      const sign = CryptoJS.MD5(signContent);

      formData.append('file', uploadFile.raw);
      formData.append('module', moduleName);
      formData.append('file_type', file_type);
      formData.append('timestamp', timestamp);
      formData.append('sign', sign);

      const options = {
        method: 'POST',
        url: '/appUpload',
        headers: {
          Authorization: sessionStorage.getItem('adminToken'),
        },
        body: formData,
        onProgress: (e) => {
          uploadFile.percent = e.percent;
        },
        onSuccess: (res) => {
          uploadFile.extra = res.extra;
          uploadFile.url = res.file_path;
          uploadFile.status = 'success';
          this.$emit('success', {
            url: res.file_path,
            extra: res.extra,
          });
        },
        onError: () => {
          uploadFile.status = 'error';
        },
      };

      uploadFile.percent = 0;
      uploadFile.fetchOptions = options;
      uploadFile.status = 'loading';
      const xhr = this.doUpload(options);
      return xhr;
    },

    doUpload(options) {
      const xhr = new XMLHttpRequest();
      xhr.open(options.method, options.url);

      xhr.upload.onprogress = (e) => {
        if (e.total > 0) {
          e.percent = (e.loaded / e.total) * 100;
        }
        options.onProgress?.(e);
      };

      xhr.onload = () => {
        if (xhr.status < 200 || xhr.status >= 300) {
          return options.onError?.(xhr);
        }
        if (xhr.response.code !== 200) {
          return options.onError?.(xhr.response);
        }
        options.onSuccess?.(xhr.response.data.list[0]);
      };

      xhr.onerror = (e) => {
        options.onError?.(e);
      };

      if (options.headers) {
        Object.keys(options.headers).forEach((key) => {
          if (options.headers[key] !== null) {
            xhr.setRequestHeader(key, options.headers[key]);
          }
        });
      }

      xhr.responseType = 'json';

      xhr.send(options.body);

      return xhr;
    },
    reUpload() {
      this.upload(this.uploadFile);
    },
  },
};
</script>

<style scoped lang="scss">
.upload {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
