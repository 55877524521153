<template>
  <div class="search-list">
    <div
      v-for="conversation in conversationList"
      class="search-item"
      :key="conversation.conversationID"
      @click="handleSelect(conversation)"
    >
      <el-image
        class="search-item-avatar"
        fit="cover"
        :src="conversation.chatData.head_img"
      />
      <div class="search-item-body">
        <div class="search-item-name">
          {{ conversation.chatData.nickname }}
        </div>
        <div class="search-item-info">
          <span>皮雀ID: {{ conversation.chatData.pic_id }}</span>
          <span>VIP: {{ conversation.chatData.is_vip ? '是' : '否' }}</span>
          <span>财富等级: {{ conversation.chatData.wealth_level }}</span>
          <span>魅力等级: {{ conversation.chatData.charm_level }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    conversationList: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    handleSelect(conversation) {
      this.$emit('select', conversation);
    },
  },
};
</script>

<style lang="scss" scoped>
.search-list {
  display: flex;
  flex-direction: column;
}
.search-item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 12px;
  cursor: pointer;

  &:hover {
    background: var(--color-primary-100);
  }
}
.search-item-avatar {
  display: flex;
  width: 36px;
  height: 36px;
  border-radius: 6px;
}
.search-item-body {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding-left: 8px;
  justify-content: space-between;
}
.search-item-name {
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.search-item-info {
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
  color: #999;
  span {
    width: 50%;
  }
}
</style>
