<template>
  <div class="message-unknown" :class="`flow-${flow}`">
    <i class="el-icon-question message-unknown-icon"></i>
    未知消息，可在皮雀APP查看。
  </div>
</template>

<script>
export default {
  props: {
    flow: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped lang="scss">
.message-unknown {
  display: flex;
  align-items: center;
  min-height: 45px;
  padding: 12px;
  font-size: 14px;
  word-wrap: break-word;
  word-break: break-all;
  color: #000;
  background: #ffc109;
  border-radius: 4px 12px 12px 12px;
  &.flow-out {
    border-radius: 12px 4px 12px 12px;
  }
}
.message-unknown-icon {
  margin-right: 4px;
  font-size: 18px;
}
</style>
