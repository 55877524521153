<template>
  <div class="message-text" :class="`flow-${flow}`">
    {{ text }}
  </div>
</template>

<script>
export default {
  props: {
    flow: {
      type: String,
      default: '',
    },
    text: {
      type: [String, Number],
      default: '',
    },
  },
};
</script>

<style scoped lang="scss">
.message-text {
  display: flex;
  flex-direction: column;
  min-width: 0px;
  min-height: 45px;
  padding: 12px;
  font-size: 14px;
  color: #464b4b;
  white-space: pre-wrap;
  word-break: break-word;
  background: #f5f9f9;
  border-radius: 4px 12px 12px 12px;

  &.flow-out {
    color: #ffffff;
    background: var(--color-primary);
    border-radius: 12px 4px 12px 12px;
  }
}
</style>
