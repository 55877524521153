import axios from "axios";

// Vue.prototype.axios = axios
import { Message, MessageBox } from "element-ui"; //导入element-ui组件库

// 代理
const BASE_URL = "/api";

// 创建axios的对象
const instance = axios.create({
  baseURL: BASE_URL, //配置固定域名
  timeout: 100000,
});

// 请求拦截
// 所有的网络请求都会走这个方法,可以在请求添加自定义内容
instance.interceptors.request.use(
  function (config) {
    // config.headers.token = '123456' // 请求头添加token值
    // config.headers.info = 'lxy'  //请求头添加info值
    let currentLocale = sessionStorage.getItem("lang");

    config.headers["Accept-Language"] = currentLocale;
    config.headers["Authorization"] = sessionStorage.getItem("adminToken");

    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// 响应拦截
// 此处可以根据服务器返回的状态码做相应的数据
instance.interceptors.response.use(
  function (response) {
    const res = response.data;

    if (res.code !== 200) {
        console.log(res.message);

      Message({
        message: res.message || "Error",
        type: "error",
        duration: 5 * 1000,
      });
      if (res.code == 403) {
        router.push({ path: "/" });
      }

      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.code === 100001 || res.code === 100002 || res.code === 100003) {
        // to re-login
        MessageBox.confirm(res.message, "提示", {
          confirmButtonText: "重新登录",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          store.dispatch("user/resetToken").then(() => {
            location.reload();
          });
        });
      }
      return Promise.reject(new Error(res.message || "Error"));
    } else {
      return res;
    }
  },
  function (error) {
    console.log("err" + error); // for debug
    Message({
      message: error.message,
      type: "error",
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  }
);

export default instance;
