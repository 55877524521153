<template>
  <div class="menu">
    <div
      v-for="item in list"
      :key="item.key"
      class="menu-item"
      @click="$emit('select', item)"
    >
      <div class="menu-item-icon">
        <svg-icon :icon-class="item.icon" />
      </div>
      <div class="menu-item-title">{{ item.title }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.menu {
  min-width: 100px;
  padding: 10px 0;
  border: 1px solid #ebeef5;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.menu-item {
  position: relative;
  display: flex;
  align-items: center;
  line-height: 32px;
  padding: 0 10px;
  font-size: 14px;
  color: #606266;
  cursor: pointer;

  &:hover {
    background: var(--color-primary-100);
    color: var(--color-primary);
  }
}
.menu-item-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  font-size: 16px;
  margin-right: 5px;
}
</style>
