<template>
  <!-- 内容盒子 -->
  <div class="home">
    <div class="window" v-loading="isLogging">
      <!-- 导航 -->
      <div class="navbar">
        <NavbarHeader :robotInfo="currentLoginRobotInfo" />
        <div class="navbar-list">
          <template v-for="item in navList">
            <div
              v-if="item.key !== 1 || currentLoginRobotInfo"
              :key="item.key"
              class="navbar-item"
              :class="{ selected: item.key === currentTab }"
              @click="currentTab = item.key"
            >
              <el-badge
                :value="getUnread(item.key)"
                :max="99"
                :hidden="getUnread(item.key) === 0"
              >
                <svg-icon
                  :icon-class="
                    item.key === currentTab ? item.selectedIcon : item.icon
                  "
                />
              </el-badge>
            </div>
          </template>
        </div>
      </div>

      <!-- 马甲号 -->
      <div v-show="currentTab === 2" class="main">
        <div class="robot">
          <div class="robot-left">
            <div class="robot-toolbar">
              <el-button
                type="text"
                :loading="closeAllRobotLoading"
                @click="onCloseAllRobotClick"
              >
                结束聊天({{ robotChatCount }})
              </el-button>
              <div>锁定聊天:{{ robotLockCount }}</div>
            </div>
            <div class="robot-list">
              <div
                v-for="robot in robotList"
                :key="robot.id"
                class="robot-item"
                :class="{
                  selected:
                    selectedRobotBaseInfo &&
                    selectedRobotBaseInfo.user_id === robot.user_id,
                }"
                @click="onRobotClick(robot)"
              >
                <el-badge
                  :value="robot.unread_count"
                  :max="99"
                  :hidden="robot.unread_count === 0"
                >
                  <el-image
                    class="robot-item-avatar"
                    fit="cover"
                    :src="robot.head_img"
                  />
                </el-badge>
                <div class="robot-item-body">
                  <div class="robot-item-name">{{ robot.nickname }}</div>
                </div>
                <div class="robot-item-footer">
                  {{
                    robot.is_lock === 1
                      ? '锁定'
                      : robot.is_chat === 1
                      ? '聊天'
                      : ''
                  }}
                </div>
              </div>
            </div>
          </div>
          <div class="robot-right">
            <!-- 马甲号信息 -->
            <div v-if="selectedRobotBaseInfo" class="robot-base-info">
              <el-descriptions title="马甲号信息" :column="1">
                <el-descriptions-item label="昵称">
                  {{ selectedRobotBaseInfo.nickname }}
                </el-descriptions-item>
                <el-descriptions-item label="皮雀ID">
                  {{ selectedRobotBaseInfo.pic_id }}
                </el-descriptions-item>
                <el-descriptions-item label="性别">
                  {{
                    getLabelByValue(selectedRobotBaseInfo.gender, genderOptions)
                  }}
                </el-descriptions-item>
              </el-descriptions>
              <div class="robot-base-info-controls">
                <el-button
                  v-if="
                    currentLoginRobotInfo &&
                    currentLoginRobotInfo.user_id ===
                      selectedRobotBaseInfo.user_id
                  "
                  type="primary"
                  round
                  @click="currentTab = 1"
                >
                  正在登录
                </el-button>
                <el-button
                  v-else
                  :loading="isLogging"
                  type="primary"
                  round
                  @click="onLoginRobotClick"
                >
                  确认登录
                </el-button>
                <el-button
                  v-if="selectedRobotBaseInfo.is_lock === 1"
                  :loading="unlockLoading"
                  type="danger"
                  round
                  @click="onUnlockRobotClick"
                >
                  解锁账号
                </el-button>
                <el-button
                  v-if="selectedRobotBaseInfo.is_lock !== 1"
                  :loading="closeLoading"
                  type="danger"
                  round
                  @click="onCloseRobotClick"
                >
                  释放账号
                </el-button>
              </div>
            </div>
            <div v-else class="robot-unselected">未选择马甲号</div>
          </div>
        </div>
      </div>

      <!-- 会话 -->
      <div v-show="currentTab === 1" class="main">
        <div class="conversation">
          <div class="conversation-left">
            <!-- 搜索会话 -->
            <Search
              :conversationList="conversationList"
              @select="selectConversation"
            />
            <!-- 会话列表 -->
            <ConversationList
              :conversationList="conversationList"
              :selectedUserId="selectedUserInfo && selectedUserInfo.user_id"
              :robotId="currentLoginRobotInfo && currentLoginRobotInfo.user_id"
              :userPrefix="user_prefix"
              @select="selectConversation"
              @delete="deleteConversation"
              ref="conversationListRef"
            />
          </div>
          <div class="conversation-right">
            <template v-if="selectedUserInfo">
              <!-- 用户信息 -->
              <UserInfo
                title="用户信息"
                :data="selectedUserInfo"
                :userData="selectedUserInfo"
                :friendSourceOptions="friendSourceOptions"
              />

              <!-- 聊天 -->
              <div class="chat">
                <!-- 聊天对象名称 -->
                <div class="chat-header">
                  <div class="chat-title">{{ selectedUserInfo.nickname }}</div>
                  <div class="chat-stat">
                    <div>今日时长 {{ formattedTodayDuration }}</div>
                    <div>今日消息 {{ selectedUserInfo.today_count }}条</div>
                  </div>
                  <div class="chat-follow">
                    <span>
                      {{ selectedUserInfo.is_followed ? '已关注' : '未关注' }}
                    </span>
                    <span @click="toggleFollow()">
                      <el-switch :value="selectedUserInfo.is_followed" />
                    </span>
                  </div>
                </div>
                <!-- 聊天内容 -->
                <div
                  class="chat-message"
                  ref="messageBox"
                  @scroll="handleChatScroll"
                >
                  <MessageList>
                    <template
                      v-for="(message, index) in selectedUserInfo.messageList"
                    >
                      <MessageItem
                        v-if="!hideMessage(message)"
                        :message="message"
                        :previousMessage="
                          selectedUserInfo.messageList[index - 1]
                        "
                        :key="message.ID"
                        :isFullMsg="isFullMsg(message)"
                        @revoke="handleRevokeMessage(message)"
                        @delete="handleDeleteMessage(message)"
                      >
                        <!-- 提示消息 -->
                        <MessageTips
                          v-if="isTips(message)"
                          :text="getTips(message)"
                        />

                        <!-- 文本消息 -->
                        <MessageText
                          v-else-if="message.type === TIM.TYPES.MSG_TEXT"
                          :flow="message.flow"
                          :text="message.payload.text"
                        />

                        <!-- 图片消息 -->
                        <MessageImage
                          v-else-if="message.type === TIM.TYPES.MSG_IMAGE"
                          :url="message.payload.imageInfoArray[0].imageUrl"
                          :width="message.payload.imageInfoArray[0].width"
                          :height="message.payload.imageInfoArray[0].height"
                        />

                        <!-- 视频消息 -->
                        <MessageVideo
                          v-else-if="message.type === TIM.TYPES.MSG_VIDEO"
                          :url="message.payload.videoUrl"
                        />

                        <!-- IP表情消息 -->
                        <MessageImage
                          v-else-if="
                            message.type === TIM.TYPES.MSG_CUSTOM &&
                            message.payload.data.action === 'emoticon'
                          "
                          :url="message.payload.data.json.url"
                          :width="message.payload.data.json.width"
                          :height="message.payload.data.json.height"
                        />

                        <!-- 语音消息 -->
                        <MessageAudio
                          v-else-if="message.type === TIM.TYPES.MSG_AUDIO"
                          :url="message.payload.url"
                          :cloudCustomData="message.cloudCustomData"
                          :second="message.payload.second"
                          :flow="message.flow"
                        />

                        <!-- 分享消息 -->
                        <MessageShare
                          v-else-if="
                            message.type === TIM.TYPES.MSG_CUSTOM &&
                            isShare(message.payload.data.action)
                          "
                          :img="message.payload.data.json.img"
                          :url="message.payload.data.json.url"
                          :title="message.payload.data.json.title"
                          :from="message.payload.data.json.nickname"
                          :type="
                            getShareLabelByAction(message.payload.data.action)
                          "
                        />

                        <!-- 礼物消息 -->
                        <MessageGift
                          v-else-if="
                            message.type === TIM.TYPES.MSG_CUSTOM &&
                            message.payload.data.action === 'gift'
                          "
                          :img="message.payload.data.json.img"
                          :name="message.payload.data.json.name"
                          :num="message.payload.data.json.num"
                        />

                        <!-- 猜拳邀请 -->
                        <MessageCyclesInvate
                          v-else-if="
                            message.type === TIM.TYPES.MSG_CUSTOM &&
                            message.payload.data.action === 'game_cycles'
                          "
                          :gameId="message.payload.data.json.game_id"
                          :robotId="currentLoginRobotInfo.user_id"
                          :friendId="selectedUserInfo.user_id"
                          :isInitiator="
                            currentLoginRobotInfo.user_id ===
                            message.payload.data.json.user_id
                          "
                        />

                        <!-- 骰子邀请 -->
                        <MessageDiceInvate
                          v-else-if="
                            message.type === TIM.TYPES.MSG_CUSTOM &&
                            message.payload.data.action === 'game_dice'
                          "
                          :gameId="message.payload.data.json.game_id"
                          :robotId="currentLoginRobotInfo.user_id"
                          :friendId="selectedUserInfo.user_id"
                        />

                        <!-- 猜拳/骰子 -->
                        <MessageGame
                          v-else-if="
                            message.type === TIM.TYPES.MSG_CUSTOM &&
                            message.payload.data.action === 'game'
                          "
                          :num="message.payload.data.json.num"
                          :type="message.payload.data.json.type"
                        />

                        <!-- 骰子结果（展示一个问题） -->
                        <MessageDiceQuection
                          v-else-if="
                            message.type === TIM.TYPES.MSG_CUSTOM &&
                            message.payload.data.action === 'game_dice_qa'
                          "
                          :sum="message.payload.data.json.question.value"
                          :question="
                            message.payload.data.json.question.question
                          "
                        />

                        <!-- 猜拳结果（选择卡片） -->
                        <MessageCyclesChoice
                          v-else-if="
                            message.type === TIM.TYPES.MSG_CUSTOM &&
                            message.payload.data.action === 'game_cycles_choice'
                          "
                          :gameId="message.payload.data.json.game_id"
                          :robotId="currentLoginRobotInfo.user_id"
                          :status="message.payload.data.json.status"
                          :choiceType="message.payload.data.json.choice_type"
                        />

                        <!-- 真心话 -->
                        <MessageTruth
                          v-else-if="
                            message.type === TIM.TYPES.MSG_CUSTOM &&
                            message.payload.data.action === 'game_truth'
                          "
                          :question="
                            message.payload.data.json.question.question
                          "
                        />

                        <!-- 大冒险 -->
                        <MessageDare
                          v-else-if="
                            message.type === TIM.TYPES.MSG_CUSTOM &&
                            message.payload.data.action === 'game_dare'
                          "
                          :question="
                            message.payload.data.json.question.question
                          "
                        />

                        <!-- 位置 -->
                        <MessageLocation
                          v-else-if="
                            message.type === TIM.TYPES.MSG_CUSTOM &&
                            message.payload.data.action === 'location'
                          "
                          :name="message.payload.data.json.name"
                          :address="message.payload.data.json.address"
                          :img="message.payload.data.json.url"
                          :latitude="message.payload.data.json.latitude"
                          :longitude="message.payload.data.json.longitude"
                        />

                        <!-- 噗通信号 -->
                        <MessageSignalCard
                          v-else-if="
                            message.type === TIM.TYPES.MSG_CUSTOM &&
                            message.payload.data.action === 'signal_card'
                          "
                          :avatar1="
                            message.payload.data.json.user_list[0].head_img
                          "
                          :avatar2="
                            message.payload.data.json.user_list[1].head_img
                          "
                          :title="message.payload.data.json.title"
                          :subtitle="message.payload.data.json.subtitle"
                        />

                        <!-- 默契100% -->
                        <MessageMatchCard
                          v-else-if="
                            message.type === TIM.TYPES.MSG_CUSTOM &&
                            message.payload.data.action === 'match_card'
                          "
                          :avatar1="
                            message.payload.data.json.user_list[0].head_img
                          "
                          :avatar2="
                            message.payload.data.json.user_list[1].head_img
                          "
                          :title="message.payload.data.json.title"
                          :subtitle="message.payload.data.json.subtitle"
                        />

                        <!-- 本地普通图片/视频消息 -->
                        <MessageLocalGeneralMedia
                          v-else-if="
                            message.type === TIM.TYPES.MSG_CUSTOM &&
                            message.payload.data.action ===
                              'local_media_general'
                          "
                          :url="message.payload.data.json.url"
                          :metaType="message.payload.data.json.metaType"
                          :width="message.payload.data.json.width"
                          :height="message.payload.data.json.height"
                          :file="message.payload.file"
                          :flow="message.flow"
                          :receiveId="selectedUserInfo.user_id"
                          :messageId="message.ID"
                          :robotId="currentLoginRobotInfo.user_id"
                        />

                        <!-- 本地阅后即焚图片/视频消息 -->
                        <MessageLocalSnapchatMedia
                          v-else-if="
                            message.type === TIM.TYPES.MSG_CUSTOM &&
                            message.payload.data.action ===
                              'local_media_snapchat'
                          "
                          :url="message.payload.data.json.url"
                          :metaType="message.payload.data.json.metaType"
                          :width="message.payload.data.json.width"
                          :height="message.payload.data.json.height"
                          :file="message.payload.file"
                          :flow="message.flow"
                          :receiveId="selectedUserInfo.user_id"
                          :messageId="message.ID"
                          :robotId="currentLoginRobotInfo.user_id"
                        />

                        <!-- 阅后即焚图片/视频消息 -->
                        <MessageSnapchatMedia
                          v-else-if="
                            message.type === TIM.TYPES.MSG_CUSTOM &&
                            message.payload.data.action ===
                              'after_destroy_media'
                          "
                          :url="message.payload.data.json.media_url"
                          :recordId="message.payload.data.json.record_id"
                          :metaType="message.payload.data.json.media_type"
                          :flow="message.flow"
                          :robotId="currentLoginRobotInfo.user_id"
                          :destroyed="message.payload.data.json.is_read === 1"
                        />

                        <MessageApplyAlbum
                          v-else-if="
                            message.type === TIM.TYPES.MSG_CUSTOM &&
                            message.payload.data.action ===
                              'apply_privacy_album'
                          "
                          :status="message.payload.data.json.status"
                          :expireAt="message.payload.data.json.expire_at"
                          :applyId="message.payload.data.json.apply_id"
                          :flow="message.flow"
                        />

                        <!-- 未知信息 -->
                        <MessageUnknown v-else :flow="message.flow" />
                      </MessageItem>
                    </template>
                  </MessageList>

                  <!-- 新消息 -->
                  <NewMsgBtn v-if="hasNewMessage" @click="onNewBtnClick()" />
                </div>
                <!-- 聊天底部 -->
                <div class="chat-footer">
                  <!-- 输入工具栏 -->
                  <div class="chat-input-toolbar">
                    <!-- emoji表情 -->
                    <EmojiPopover @select="selectEmoji">
                      <div class="chat-input-toolbar-item">
                        <svg-icon icon-class="emoji" style="font-size: 20px" />
                      </div>
                    </EmojiPopover>

                    <!-- ip1 emoji表情 -->
                    <IpEmojiPopover
                      :list="pique_emoticon"
                      @select="selectIPEmoji"
                    >
                      <div class="chat-input-toolbar-item">
                        <img
                          src="@/images/home/contact/ic_biaoqing_pique@2x.png"
                        />
                      </div>
                    </IpEmojiPopover>

                    <!-- ip2 emoji表情 -->
                    <IpEmojiPopover
                      :list="pique2_emoticon"
                      @select="selectIPEmoji"
                    >
                      <div class="chat-input-toolbar-item">
                        <img
                          src="@/images/home/contact/ic_biaoqing_pique2@2x.png"
                        />
                      </div>
                    </IpEmojiPopover>

                    <!-- 文件上传（新：走自己服务器） -->
                    <FilePopover
                      :isVip="currentLoginRobotInfo.is_vip"
                      @change="onFileUploadChange"
                    >
                      <div class="chat-input-toolbar-item">
                        <svg-icon icon-class="folder" />
                      </div>
                    </FilePopover>

                    <!-- 文件上传（旧：走腾讯云） -->
                    <!-- <div class="chat-input-toolbar-item">
                      <el-upload
                        class="flex items-center justify-center"
                        :accept="'image/*,video/*'"
                        :show-file-list="false"
                        :before-upload="beforeUpload"
                        action=""
                      >
                        <svg-icon icon-class="folder" />
                      </el-upload>
                    </div> -->

                    <!-- 话术选择 -->
                    <ScriptPopover @select="selectScript">
                      <div class="chat-input-toolbar-item">
                        <svg-icon icon-class="verbal" />
                      </div>
                    </ScriptPopover>

                    <!-- 游戏 -->
                    <GamePopover
                      :friendId="selectedUserInfo.user_id"
                      :robotId="currentLoginRobotInfo.user_id"
                    >
                      <div class="chat-input-toolbar-item">
                        <svg-icon icon-class="gamepad" />
                      </div>
                    </GamePopover>

                    <!-- 赠送礼物 -->
                    <GiftPopover
                      :friendId="selectedUserInfo.user_id"
                      :robotId="currentLoginRobotInfo.user_id"
                    >
                      <div class="chat-input-toolbar-item">
                        <img
                          src="@/images/home/contact/ic_duihua_songliwu@2x.png"
                        />
                      </div>
                    </GiftPopover>

                    <!-- 聊天记录 -->
                    <div
                      class="ml-auto chat-input-toolbar-item"
                      @click="
                        showChatLog(
                          currentLoginRobotInfo.user_id,
                          selectedUserInfo.user_id,
                        )
                      "
                    >
                      <svg-icon icon-class="chat-log" />
                    </div>
                  </div>
                  <!-- 聊天输入 -->
                  <textarea
                    maxlength="500"
                    class="chat-input"
                    v-model="chatText"
                    ref="chatInput"
                    placeholder="请输入消息"
                    @keyup.ctrl.enter.exact="handleEnter('ctrl+enter', $event)"
                    @keyup.enter.exact="handleEnter('enter', $event)"
                    @keydown.enter.prevent
                  ></textarea>

                  <ChatSendBtn
                    :disabled="!chatText.trim()"
                    @click="handleEnter('enter')"
                  />
                </div>
              </div>

              <!-- 马甲号信息 -->
              <UserInfo
                title="马甲号信息"
                :data="currentLoginRobotInfo"
                :userData="selectedUserInfo"
                isRobot
              />
            </template>
            <div v-else class="conversation-unselected">未选择会话</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TIM from 'tim-js-sdk';
import TIMUploadPlugin from 'tim-upload-plugin';
import TIMProfanityFilterPlugin from 'tim-profanity-filter-plugin';

import { formatTimeBySeconds, getLabelByValue, log, random } from '@/utils';

import ConversationList from '@/components/ConversationList.vue';
import NavbarHeader from '@/components/NavbarHeader.vue';

import MessageList from '@/components/MessageList.vue';
import MessageItem from '@/components/MessageItem.vue';
import MessageTips from '@/components/MessageTips.vue';
import MessageText from '@/components/MessageText.vue';
import MessageImage from '@/components/MessageImage.vue';
import MessageVideo from '@/components/MessageVideo.vue';
import MessageAudio from '@/components/MessageAudio.vue';
import MessageShare from '@/components/MessageShare.vue';
import MessageGift from '@/components/MessageGift.vue';
import MessageCyclesInvate from '@/components/MessageCyclesInvate.vue';
import MessageDiceInvate from '@/components/MessageDiceInvate.vue';
import MessageGame from '@/components/MessageGame.vue';
import MessageTruth from '@/components/MessageTruth.vue';
import MessageDare from '@/components/MessageDare.vue';
import MessageDiceQuection from '@/components/MessageDiceQuection.vue';
import MessageCyclesChoice from '@/components/MessageCyclesChoice.vue';
import MessageLocalGeneralMedia from '@/components/MessageLocalGeneralMedia.vue';
import MessageLocalSnapchatMedia from '@/components/MessageLocalSnapchatMedia.vue';
import MessageSnapchatMedia from '@/components/MessageSnapchatMedia.vue';
import MessageLocation from '@/components/MessageLocation.vue';
import MessageSignalCard from '@/components/MessageSignalCard.vue';
import MessageMatchCard from '@/components/MessageMatchCard.vue';
import MessageApplyAlbum from '@/components/MessageApplyAlbum.vue';
import MessageUnknown from '@/components/MessageUnknown.vue';

import EmojiPopover from '@/components/EmojiPopover.vue';
import IpEmojiPopover from '@/components/IpEmojiPopover.vue';
import ScriptPopover from '@/components/ScriptPopover.vue';
import GamePopover from '@/components/GamePopover.vue';
import GiftPopover from '@/components/GiftPopover.vue';
import FilePopover from '@/components/FilePopover.vue';

import ChatSendBtn from '@/components/ChatSendBtn.vue';
import NewMsgBtn from '@/components/NewMsgBtn.vue';

import Search from '@/components/Search.vue';
import UserInfo from '@/components/UserInfo.vue';

import { genderOptions } from '@/constant/common';

import {
  getRobotList,
  getRobotConfig,
  loginRobot,
  getRobotChat,
  startRobot,
  getRobotInfo,
  getRobotToday,
  actFollow,
  logoutRobot,
  closeRobot,
  unlockRobot,
  reportDice,
  reportCycles,
  sendCheck,
} from '@/api/chat';
import { getFileType, getImageSize } from '@/utils/file';

/*
todo:
[x] 图片/视频消息-上传展示（上传状态、重新上传）
[x] 上传反馈消息处理（删除上传消息/修改上传消息状态）
[x] 图片/视频消息-成功展示
[x] 图片/视频预览
[x] 图片/视频消息变更
[x] 权限管理

*/
import { getOption } from '@/api/common';

export default {
  components: {
    ConversationList,
    NavbarHeader,
    MessageList,
    MessageItem,
    MessageTips,
    MessageText,
    MessageImage,
    MessageVideo,
    MessageAudio,
    MessageShare,
    MessageGift,
    MessageCyclesInvate,
    MessageDiceInvate,
    MessageGame,
    MessageTruth,
    MessageDare,
    MessageDiceQuection,
    MessageCyclesChoice,
    MessageLocalGeneralMedia,
    MessageLocalSnapchatMedia,
    MessageSnapchatMedia,
    MessageLocation,
    MessageSignalCard,
    MessageMatchCard,
    MessageApplyAlbum,
    MessageUnknown,
    EmojiPopover,
    IpEmojiPopover,
    ScriptPopover,
    GamePopover,
    GiftPopover,
    FilePopover,
    ChatSendBtn,
    NewMsgBtn,
    Search,
    UserInfo,
  },

  data() {
    return {
      isDestroyed: false,
      currentTab: 2, // 1.会话  2.马甲号
      navList: [
        {
          key: 2,
          icon: 'person',
          selectedIcon: 'person-fill',
        },
        {
          key: 1,
          icon: 'chat-dots',
          selectedIcon: 'chat-dots-fill',
        },
      ],

      // conversationID前缀: C2C + user_prefix + uid
      user_prefix: '',
      // 聊天敏感词
      sensitive_words: [],
      // IP表情列表
      pique_emoticon: [],
      pique2_emoticon: [],
      // SDKAppID
      appid: '',
      system_user_id: null,

      // TIM SDK 实例
      TIM,
      chat: null,
      isMonitoring: false,

      // 马甲号相关
      allRobotUnreadCount: 0,
      robotChatCount: 0,
      robotLockCount: 0,
      robotList: [],
      selectedRobotBaseInfo: null,
      isLogging: false,
      currentLoginRobotInfo: null,
      unlockLoading: false,
      closeLoading: false,
      closeAllRobotLoading: false,

      // 会话相关
      conversationList: [],
      chatList: [],
      unreadCount: 0,
      selectedUserInfo: null,
      selectedUserInfoList: [],

      // 单个会话聊天时间
      startCurrentTime: false,
      // 当前累计时间
      currentTodyDuration: 0,
      chatTimer: null,

      // 聊天相关
      chatText: '',
      hasNewMessage: false,

      genderOptions,
      friendSourceOptions: [],
    };
  },

  computed: {
    formattedTodayDuration() {
      return formatTimeBySeconds(this.currentTodyDuration);
    },
  },

  created() {
    sessionStorage.setItem('adminToken', this.$route.query.token);
    this.bootstrapFlow();
  },

  beforeDestroy() {
    this.isDestroyed = true;
    this.stopCharTimer();
    this.chat?.destroy();
    this.isMonitoring = false;
  },

  watch: {
    conversationList() {
      this.unreadCount = this.conversationList.reduce((count, conversation) => {
        return count + conversation.unreadCount;
      }, 0);
    },
  },

  methods: {
    getLabelByValue,

    getUnread(key) {
      switch (key) {
        case 1:
          return this.unreadCount;
        case 2:
          return this.allRobotUnreadCount;
        default:
          return 0;
      }
    },

    hideMessage(message) {
      if (message.type === TIM.TYPES.MSG_CUSTOM) {
        const data = message.payload.data;
        const keys = Object.keys(data);

        // 音视频邀请
        if (keys.includes('inviteID') && keys.includes('inviteeList')) {
          return true;
        }
      }
    },

    // 指令消息
    // https://codeup.aliyun.com/youxun/pic/doc/blob/dev/%E6%8E%A5%E5%8F%A3%E6%96%87%E6%A1%A3/App%E6%8E%A5%E5%8F%A3/%E8%81%8A%E5%A4%A9%E4%BC%9A%E8%AF%9D/%E6%8C%87%E4%BB%A4%E6%B6%88%E6%81%AF/C2C%E6%B6%88%E6%81%AF%E4%BD%93.md
    isFullMsg(message) {
      // 撤回消息
      if (message.isRevoked) {
        return true;
      }

      if (message.type === TIM.TYPES.MSG_CUSTOM) {
        const actions = [
          'text_tips',
          'safe_tip',
          'game_dice_qa',
          'game_cycles_choice',
          'signal_card',
          'match_card',
          'chat_call_up',
          'offline_push_no_online',
          'rtc_text_msg',
        ];
        if (actions.includes(message.payload.data.action)) {
          return true;
        }
      }
    },

    isTips(message) {
      // 撤回消息
      if (message.isRevoked) {
        return true;
      }

      if (message.type === TIM.TYPES.MSG_CUSTOM) {
        const actions = [
          'text_tips',
          'safe_tip',
          'chat_call_up',
          'offline_push_no_online',
          'rtc_text_msg',
        ];
        if (actions.includes(message.payload.data.action)) {
          return true;
        }
      }
    },

    getTips(message) {
      // 撤回消息
      if (message.isRevoked) {
        return `${message.flow === 'out' ? '你' : '对方'}撤回了一条消息`;
      }

      const data = message.payload.data;

      // 安全提示
      if (data.action === 'safe_tip') {
        return data.json.tip;
      }

      // 召唤上线
      if (data.action === 'chat_call_up') {
        return data.json.message;
      }

      // 通话
      if (data.action === 'offline_push_no_online') {
        return data.json.content;
      }

      // 音视频消息
      if (data.action === 'rtc_text_msg') {
        const modeText = { voice: '音频', video: '视频' }[data.json.room_mode];
        return `${modeText}消息: ${
          data.json.msg[this.selectedRobotBaseInfo.user_id]
        }`;
      }

      // 双方消息
      if (
        ['both', 'game_dice_start', 'game_cycles_start'].includes(
          data.json.type,
        )
      ) {
        return data.json.extra[this.selectedRobotBaseInfo.user_id];
      }

      // 其他默认取msg
      return data.json.msg;
    },

    isShare(type) {
      return ['dynamics', 'topic', 'seekGift', 'h5_share'].includes(type);
    },

    getShareLabelByAction(action) {
      return {
        dynamics: '动态',
        topic: '话题',
        seekGift: '情报局',
        h5_share: 'h5',
      }[action];
    },

    async bootstrapFlow() {
      await this.getImConfig();
      await this.createTim();
      await this.getAndMonitorRobot();
      this.getBasicData();
    },

    async getImConfig() {
      const res = await getRobotConfig();
      this.user_prefix = res.data.user_prefix;
      this.sensitive_words = res.data.sensitive_words;
      this.pique_emoticon = res.data.pique_emoticon || [];
      this.pique2_emoticon = res.data.pique2_emoticon || [];
      this.appid = res.data.appid;
      this.system_user_id = res.data.system_user_id;
      localStorage.setItem('sign_salt', res.data.sign_salt);
    },

    async createTim() {
      this.chat = TIM.create({
        SDKAppID: this.appid, // 即时通信应用的 SDKAppID,
      });
      // dev级别用0，release级别用1，SDK 输出关键信息
      this.chat.setLogLevel(process.env.NODE_ENV === 'development' ? 1 : 1);
      this.chat.registerPlugin({
        'tim-profanity-filter-plugin': TIMProfanityFilterPlugin,
        'tim-upload-plugin': TIMUploadPlugin,
      });
      this.monitorTIM();
    },

    getAndMonitorRobot() {
      this.doGetMonitorRobotUpdate();
    },

    async doGetMonitorRobotUpdate() {
      const res = await getRobotList();

      this.allRobotUnreadCount = res.data.list.reduce((count, item) => {
        return count + item.unread_count;
      }, 0);

      this.robotChatCount = res.data.chat;
      this.robotLockCount = res.data.lock;
      this.robotList = res.data.list;

      setTimeout(() => {
        if (!this.isDestroyed) {
          this.doGetMonitorRobotUpdate();
        }
      }, 10000);
    },

    getBasicData() {
      getOption({ keyword: ['chat_friend_source'] }).then((res) => {
        this.friendSourceOptions = res.data.chat_friend_source.map((item) => {
          return {
            label: item.text,
            value: Number(item.value),
          };
        });
      });
    },

    onRobotClick(robot) {
      if (this.isLogging) {
        this.$message.warning('正在登录马甲号');
        return;
      }
      if (this.selectedRobotBaseInfo?.user_id !== robot.user_id) {
        this.selectedRobotBaseInfo = robot;
      }
    },

    onLoginRobotClick() {
      if (this.isLogging) {
        return;
      }
      this.LoginFlow();
    },

    async LoginFlow() {
      this.isLogging = true;

      await this.tryLogout();
      await this.deleteTIMLocalStorage();
      const res = await this.loginRobot();
      await this.loginTIM(res.data.im.user_sig);

      this.isLogging = false;
      this.currentTab = 1;
      this.$nextTick(() => {
        this.$refs.conversationListRef?.scrollToTop();
      });
    },

    async tryLogout() {
      if (this.currentLoginRobotInfo) {
        await this.logoutTIM();
        await this.logoutRobot();
      }
    },

    async deleteTIMLocalStorage() {
      Object.keys(localStorage).forEach((key) => {
        if (/^TIM_.*?_(?:profile|groupMap|conversationMap)$/.test(key)) {
          localStorage.removeItem(key);
        }
      });
    },

    async logoutTIM() {
      await this.chat.logout();
    },

    async logoutRobot() {
      await logoutRobot({
        user_id: this.currentLoginRobotInfo.user_id,
      });
      this.stopCharTimer();
      this.currentTodyDuration = 0;
      this.startCurrentTime = false;
      this.currentLoginRobotInfo = null;
      this.selectedUserInfo = null;
      this.selectedUserInfoList = [];
      this.conversationList = [];
      this.$forceUpdate();
    },

    async loginRobot() {
      const res = await loginRobot({
        user_id: this.selectedRobotBaseInfo.user_id,
      });
      this.currentLoginRobotInfo = res.data;
      return res;
    },

    async loginTIM(userSig) {
      await this.chat.login({
        userID: this.user_prefix + this.selectedRobotBaseInfo.user_id,
        userSig,
      });
    },

    monitorTIM() {
      if (!this.isMonitoring) {
        this.doMonitorTIM();
        this.isMonitoring = true;
      }
    },

    doMonitorTIM() {
      const onSDKReady = () => {
        this.TIMSDKReadyFlow();
      };
      this.chat.on(TIM.EVENT.SDK_READY, onSDKReady);

      const onConversationListUpdate = (event) => {
        this.TIMConversationUpdatedFlow(event.data);
      };
      this.chat.on(
        TIM.EVENT.CONVERSATION_LIST_UPDATED,
        onConversationListUpdate,
      );

      const onMessageReceived = (event) => {
        this.TIMMessageReceivedFlow(event.data);
      };
      this.chat.on(TIM.EVENT.MESSAGE_RECEIVED, onMessageReceived);

      const onMessageRevoked = (event) => {
        this.TIMMessageRevokedFlow(event.data);
      };
      this.chat.on(TIM.EVENT.MESSAGE_REVOKED, onMessageRevoked);

      const onMessageModified = (event) => {
        this.TIMMessageModifiedFlow(event.data);
      };
      this.chat.on(TIM.EVENT.MESSAGE_MODIFIED, onMessageModified);
    },

    async TIMSDKReadyFlow() {
      const chatList = await this.getChatList();
      const TIMConversationList = await this.getTIMConversationList();
      log('会话列表', TIMConversationList);
      this.mergeConversationList(TIMConversationList, chatList);
    },

    async getChatList() {
      const res = await getRobotChat({
        user_id: this.selectedRobotBaseInfo.user_id,
      });
      return (this.chatList = res.data);
    },

    async getTIMConversationList() {
      const imResponse = await this.chat.getConversationList();
      return imResponse.data.conversationList;
    },

    async mergeConversationList(TIMConversationList, chatList) {
      TIMConversationList = TIMConversationList.filter((conversation) => {
        const conversationUserID = conversation.userProfile.userID;
        // 过滤官网消息会话
        if (conversationUserID === 'php_service') {
          return false;
        }
        // 过滤系统用户会话
        if (conversationUserID === this.user_prefix + this.system_user_id) {
          return false;
        }
        // 过滤官方接口没有的会话
        if (
          chatList.every((chatItem) => {
            return this.user_prefix + chatItem.user_id !== conversationUserID;
          })
        ) {
          return false;
        }
        return true;
      });

      this.conversationList = TIMConversationList.map((conversation) => {
        chatList.some((chatItem) => {
          if (
            this.user_prefix + chatItem.user_id ===
            conversation.userProfile.userID
          ) {
            conversation.chatData = chatItem;
            return true;
          }
        });
        return conversation;
      });
    },

    async TIMConversationUpdatedFlow(TIMConversationList) {
      log('会话列表更新', TIMConversationList);
      const chatList = this.checkForNewConversation(TIMConversationList)
        ? await this.getChatList()
        : this.chatList;
      await this.mergeConversationList(TIMConversationList, chatList);
    },

    checkForNewConversation(TIMConversationList) {
      return TIMConversationList.some((conversation) => {
        return this.chatList.every((chatItem) => {
          return (
            this.user_prefix + chatItem.user_id !==
            conversation.userProfile.userID
          );
        });
      });
    },

    TIMMessageReceivedFlow(messageList) {
      log('收到消息', messageList);
      this.appendToMessageList(messageList);
      this.tryStartGame(messageList);
    },

    appendToMessageList(messageList) {
      messageList.forEach((message) => {
        if (this.mayUpdateLocalMessage(message)) {
          return;
        }

        if (this.makeLocalMessageFailed(message)) {
          return;
        }

        const user_id = Number(message.conversationID.split('_')[1]);
        for (const userInfo of this.selectedUserInfoList) {
          if (userInfo.user_id === user_id) {
            userInfo.messageList.push(this.makeCustomMessage(message));
          }
        }

        if (this.selectedUserInfo?.user_id === user_id) {
          const messageBox = this.$refs.messageBox;
          if (
            messageBox.scrollTop >=
            messageBox.scrollHeight - messageBox.clientHeight - 210
          ) {
            this.$nextTick(() => {
              this.scrollToBottom();
            });
          } else {
            this.hasNewMessage = true;
          }
        }
      });
    },

    mayUpdateLocalMessage(message) {
      let localMessageId;
      if (
        message.type === TIM.TYPES.MSG_IMAGE ||
        message.type === TIM.TYPES.MSG_VIDEO
      ) {
        try {
          const cloudCustomData = JSON.parse(
            message.cloudCustomData,
          ).cloud_data;
          if (cloudCustomData?.local_msg_id && message.flow === 'out') {
            localMessageId = cloudCustomData.local_msg_id;
          }
        } catch (err) {
          void err;
        }
      } else if (message.type === TIM.TYPES.MSG_CUSTOM) {
        const data = JSON.parse(message.payload.data);
        if (data.action === 'after_destroy_media' && message.flow === 'out') {
          localMessageId = data.json.local_msg_id;
        }
      }

      if (localMessageId) {
        for (const userInfo of this.selectedUserInfoList) {
          if (
            this.updateMessage(message, localMessageId, userInfo.messageList)
          ) {
            return true;
          }
        }
      }
    },

    makeLocalMessageFailed(message) {
      if (message.type === TIM.TYPES.MSG_CUSTOM) {
        const data = JSON.parse(message.payload.data);
        // “资源审核结果指令”消息
        if (data.action === 'chat_media_audit') {
          // 审核失败
          if (data.json.audit_status === 2) {
            this.$bus.$emit('status-change', {
              messageId: data.json.local_msg_id,
              status: 'error',
            });
            return true;
          }
        }
      }
    },

    TIMMessageRevokedFlow(messageList) {
      log('撤回消息', messageList);
      this.updateMessageList(messageList);
    },

    TIMMessageModifiedFlow(messageList) {
      log('变更消息', messageList);
      this.updateMessageList(messageList);
    },

    updateMessageList(messageList) {
      messageList.forEach((message) => {
        for (const userInfo of this.selectedUserInfoList) {
          if (this.updateMessage(message, message.ID, userInfo.messageList)) {
            break;
          }
        }
      });
    },

    updateMessage(message, messageId, messageList) {
      const index = messageList.findIndex((msg) => msg.ID === messageId);
      if (index !== -1) {
        messageList.splice(index, 1, this.makeCustomMessage(message));
        return true;
      }
    },

    // =================

    onCloseAllRobotClick() {
      if (this.robotChatCount === 0) {
        return;
      }
      this.$confirm(
        '结束聊天后所有未锁定马甲号不再出现在列表，确定结束？',
        '结束聊天',
        {
          type: 'warning',
        },
      ).then(async () => {
        this.closeAllRobotLoading = true;

        if (this.currentLoginRobotInfo) {
          const robot = this.robotList.find(
            (item) => item.user_id === this.currentLoginRobotInfo.user_id,
          );
          if (robot && robot.is_lock !== 1) {
            await this.tryLogout();
          }
        }

        closeRobot()
          .then(() => {
            this.$message.success('操作成功');
            this.robotChatCount = 0;
            this.robotList = this.robotList.filter(
              (robot) => robot.is_lock === 1,
            );
            if (
              this.selectedRobotBaseInfo &&
              this.selectedRobotBaseInfo.is_lock !== 1
            ) {
              this.selectedRobotBaseInfo = null;
            }
          })
          .finally(() => {
            this.closeAllRobotLoading = false;
          });
      });
    },

    onCloseRobotClick() {
      this.$confirm(
        '释放账号后该马甲号不再出现在列表，确定释放？',
        '释放账号',
        {
          type: 'warning',
        },
      ).then(async () => {
        const userId = this.selectedRobotBaseInfo.user_id;
        if (this.currentLoginRobotInfo?.user_id === userId) {
          await this.tryLogout();
        }
        this.closeLoading = true;
        closeRobot({
          user_id: userId,
        })
          .then(() => {
            this.$message.success('操作成功');
            this.robotList = this.robotList.filter(
              (item) => item.user_id !== userId,
            );
            this.selectedRobotBaseInfo = null;
          })
          .finally(() => {
            this.closeLoading = false;
          });
      });
    },

    onUnlockRobotClick() {
      this.$confirm(
        '解锁账号后该马甲号不再出现在列表，确定解锁？',
        '解锁账号',
        {
          type: 'warning',
        },
      ).then(async () => {
        const userId = this.selectedRobotBaseInfo.user_id;
        if (this.currentLoginRobotInfo?.user_id === userId) {
          await this.tryLogout();
        }
        this.unlockLoading = true;
        unlockRobot({
          user_id: userId,
        })
          .then(() => {
            this.$message.success('操作成功');
            this.robotList = this.robotList.filter(
              (item) => item.user_id !== userId,
            );
            this.selectedRobotBaseInfo = null;
          })
          .finally(() => {
            this.unlockLoading = false;
          });
      });
    },

    toggleFollow() {
      actFollow({
        robot_id: this.currentLoginRobotInfo.user_id,
        user_id: this.selectedUserInfo.user_id,
      }).then(() => {
        this.$message.success('操作完成');
        const newIsFollowed = !this.selectedUserInfo.is_followed;
        this.selectedUserInfo.is_followed = newIsFollowed;
        // this.$forceUpdate();
      });
    },

    deleteConversation(conversation) {
      this.$confirm('删除聊天后对话将不再出现在列表，确定删除？', '结束删除', {
        type: 'warning',
      }).then(() => {
        const conversationID =
          'C2C' + this.user_prefix + conversation.chatData.user_id;
        this.chat
          .deleteConversation({
            conversationIDList: [conversationID],
            clearHistoryMessage: false,
          })
          .then(() => {
            if (
              this.selectedUserInfo?.user_id === conversation.chatData.user_id
            ) {
              this.stopCharTimer();
              this.selectedUserInfo = null;
            }
            const index = this.selectedUserInfoList.findIndex(
              (item) => item.user_id === conversation.chatData.user_id,
            );
            if (index !== -1) {
              this.selectedUserInfoList.splice(index, 1);
            }
          });
      });
    },

    selectConversation(conversation) {
      if (this.selectedUserInfo?.user_id !== conversation.chatData.user_id) {
        this.selectConversationFlow(conversation);
      }
    },

    async selectConversationFlow(conversation) {
      const chatId = conversation.chatData.chat_id;
      const userId = conversation.chatData.user_id;

      this.flushChatData();

      const userInfo = this.getSelectedUserInfo(userId);

      if (userInfo) {
        this.selectedUserInfo = userInfo;
      } else {
        this.startChat(chatId);
        await this.getUserInfo(chatId);
        await this.getHistoryMessage();
      }

      this.scrollToBottomAndTalk();
      this.setChatMessageRead(conversation.conversationID);

      await this.updateTodayInfo(chatId);
      this.currentTodyDuration = this.selectedUserInfo.today_duration;
      this.startChatTimer();
    },

    flushChatData() {
      this.chatText = '';
      this.hasNewMessage = false;
    },

    getSelectedUserInfo(userId) {
      for (const userInfo of this.selectedUserInfoList) {
        if (userInfo.user_id === userId) {
          return userInfo;
        }
      }
    },

    async updateTodayInfo(chat_id) {
      const res = await getRobotToday({ chat_id });
      this.selectedUserInfo.today_count = res.data.today_count;
      this.selectedUserInfo.today_duration = res.data.today_duration;
    },

    async startChat(chat_id) {
      return startRobot({ chat_id });
    },

    async getUserInfo(chat_id) {
      const res = await getRobotInfo({ chat_id });
      res.data.chat_id = chat_id;
      this.selectedUserInfo = res.data;
      this.selectedUserInfoList.push(res.data);
    },

    async getHistoryMessage() {
      if (
        this.selectedUserInfo.isGettingMessage ||
        this.selectedUserInfo.isCompleted
      ) {
        return;
      }
      this.selectedUserInfo.isGettingMessage = true;
      const imResponse = await this.chat.getMessageList({
        conversationID: `C2C${
          this.user_prefix + this.selectedUserInfo.user_id
        }`,
        nextReqMessageID: this.selectedUserInfo.nextReqMessageID,
      });
      const messageList = imResponse.data.messageList;
      log('消息列表', messageList);

      const extraObjects = {
        isGettingMessage: false,
        nextReqMessageID: imResponse.data.nextReqMessageID,
        isCompleted: imResponse.data.isCompleted,
        messageList: [
          ...messageList.map((message) => {
            return this.makeCustomMessage(message);
          }),
          ...(this.selectedUserInfo.messageList || []),
        ],
      };
      for (const key in extraObjects) {
        this.$set(this.selectedUserInfo, key, extraObjects[key]);
      }
    },

    makeCustomMessage(message) {
      return {
        ...message,
        payload:
          message.type === TIM.TYPES.MSG_CUSTOM
            ? {
                ...message.payload,
                data:
                  typeof message.payload.data === 'string' &&
                  message.payload.data !== ''
                    ? JSON.parse(message.payload.data)
                    : message.payload.data,
              }
            : message.payload,
      };
    },

    startChatTimer() {
      if (!this.startCurrentTime) {
        this.doStartChatTimer();
        this.startCurrentTime = true;
      }
    },

    doStartChatTimer() {
      this.chatTimer = setInterval(() => {
        this.currentTodyDuration++;
      }, 1000);
    },

    stopCharTimer() {
      clearInterval(this.chatTimer);
    },

    scrollToBottomAndTalk() {
      this.$nextTick(() => {
        this.scrollToBottom();
        this.$refs.chatInput.focus();
      });
    },

    onNewBtnClick() {
      this.scrollToBottom();
    },

    scrollToBottom() {
      const messageBox = this.$refs.messageBox;
      messageBox.scrollTop = messageBox.scrollHeight;
    },

    selectEmoji(emoji) {
      this.chatText += emoji;
      this.$nextTick(() => {
        this.$refs.chatInput.focus();
      });
    },

    selectIPEmoji(item) {
      this.sendIPEmoji(item);
    },

    beforeUpload(file) {
      let type = file.type.split('/')[0];
      if (type === 'image') {
        this.sendImage(file);
      } else if (type === 'video') {
        this.sendVideo(file);
      }
    },

    selectScript(content) {
      this.chatText = content;
      this.$nextTick(() => {
        this.$refs.chatInput.focus();
      });
    },

    handleEnter(type) {
      if (type === 'ctrl+enter') {
        this.chatText += '\n';
      } else if (type === 'enter') {
        if (this.chatText.trim() === '') {
          this.$message.warning('发送消息不能为空！');
          return;
        }
        // 敏感词检测
        if (this.sensitive_words.some((word) => this.chatText.includes(word))) {
          this.$message.warning('含有敏感词，请检查文案');
          return;
        }
        this.sendText(this.chatText.trim());
      }
    },

    // 发送文本消息
    sendText(data) {
      this.sendMessage(
        'text',
        {
          text: data,
        },
        {
          type: 'text',
          content: data,
        },
      );
      this.chatText = '';
    },

    // 发送图片
    sendImage(file) {
      this.sendMessage(
        'image',
        {
          file,
        },
        {
          type: 'image',
          content: file,
        },
      );
    },

    // 发送视频
    sendVideo(file) {
      this.sendMessage(
        'video',
        {
          file,
        },
        {
          type: 'video',
          content: file,
        },
      );
    },

    // 发送IP表情
    sendIPEmoji(item) {
      this.sendMessage(
        'custom',
        {
          data: JSON.stringify({
            action: 'emoticon',
            json: item,
          }),
          description: '',
          extension: '',
        },
        {
          type: 'emoticon',
          content: item,
        },
      );
    },

    // 统一处理消息发布
    async sendMessage(type, payload, meta = {}) {
      // 创建离线消息
      const ext = JSON.stringify({
        params: {
          user_id: this.currentLoginRobotInfo.user_id,
          head_img: this.currentLoginRobotInfo.head_img,
          nickname: this.currentLoginRobotInfo.nickname,
        },
        type: 'im_chat',
      });
      const chartId = this.user_prefix + this.selectedUserInfo.user_id;

      const mapTypeMethod = {
        image: 'createImageMessage',
        video: 'createVideoMessage',
        text: 'createTextMessage',
        custom: 'createCustomMessage',
      };

      const message = this.chat[mapTypeMethod[type]]({
        to: chartId,
        conversationType: TIM.TYPES.CONV_C2C,
        payload,
        needReadReceipt: true,
      });

      const content =
        meta.type === 'text'
          ? meta.content
          : meta.type === 'emoticon'
          ? meta.content.emoticon_id
          : '';

      const msg_type = meta.type || '';

      try {
        const checkRes = await sendCheck({
          robot_id: this.currentLoginRobotInfo.user_id,
          friend_id: this.selectedUserInfo.user_id,
          content,
          msg_type,
          local_msg_id: message.ID,
        });

        /*
        // tips: 由后端发送消息，本地不需要发送
        
        message.cloudCustomData = JSON.stringify({
          cloud_data: null,
          message_tips: checkRes.data.messages?.[0]?.message_tips,
        });

        await this.chat.sendMessage(message, {
          offlinePushInfo: {
            title: this.currentLoginRobotInfo.nickname, // 离线推送标题
            description: '私聊了你,对你很心动~', // 离线推送内容
            extension: ext, //离线推送透传内容
          },
        });

        log('发送消息', message);
        this.selectedUserInfo.messageList.push(this.makeCustomMessage(message));
        this.scrollToBottomAndTalk();

        this.selectedUserInfo.today_count++;
        */
      } catch (error) {
        console.error('消息发送失败:', error);
        this.$bus.$emit(message.ID, {
          type: 'status',
          status: 'error',
        });
      }
    },

    async onFileUploadChange(file, type) {
      const message = await this.createCustomMediaMessage(file, type);
      this.sendLocalMessage(message);
    },

    async createCustomMediaMessage(file, type) {
      const chartId = this.user_prefix + this.selectedUserInfo.user_id;
      const metaType = getFileType(file);
      const { width, height } =
        metaType === 'image' ? await getImageSize(file) : {};
      const message = this.chat.createCustomMessage({
        to: chartId,
        conversationType: TIM.TYPES.CONV_C2C,
        payload: {
          data: JSON.stringify({
            action: `local_media_${type}`,
            json: {
              metaType,
              url: URL.createObjectURL(file),
              width,
              height,
            },
          }),
        },
        needReadReceipt: true,
      });
      message.payload.file = file;
      return message;
    },

    sendLocalMessage(message) {
      log('发送本地消息', message);
      this.selectedUserInfo.messageList.push(this.makeCustomMessage(message));
      this.selectedUserInfo.today_count++;

      this.scrollToBottomAndTalk();
    },

    async handleChatScroll() {
      this.getPreviousHistoryMessage();

      const messageBox = this.$refs.messageBox;
      if (
        messageBox.scrollTop >=
        messageBox.scrollHeight - messageBox.clientHeight - 36
      ) {
        for (const conversation of this.conversationList) {
          if (conversation.chatData.user_id === this.selectedUserInfo.user_id) {
            if (conversation.unreadCount > 0) {
              this.setChatMessageRead(
                `C2C${this.user_prefix + this.selectedUserInfo.user_id}`,
              );
            }
            break;
          }
        }
      }
    },

    getPreviousHistoryMessage() {
      const messageBox = this.$refs.messageBox;
      if (
        messageBox.scrollTop === 0 &&
        this.selectedUserInfo &&
        this.selectedUserInfo.messageList &&
        !this.selectedUserInfo.isCompleted
      ) {
        this.doGetPreviousHistoryMessage();
      }
    },

    async doGetPreviousHistoryMessage() {
      const messageBox = this.$refs.messageBox;
      const firstMsgId = this.selectedUserInfo.messageList[0].ID;
      await this.getHistoryMessage();
      const oldScrollTop = messageBox.scrollTop;
      this.$nextTick(() => {
        const currentScrollTop = messageBox.scrollTop;
        const firstMsgEl = document.getElementById(firstMsgId);
        const firstMsgTop = firstMsgEl.getBoundingClientRect().top;
        const messageBoxTop = messageBox.getBoundingClientRect().top;
        const offsetHeight = firstMsgTop - messageBoxTop;
        const nextScrollTop = oldScrollTop + offsetHeight + currentScrollTop;
        messageBox.scrollTop = nextScrollTop;
      });
    },

    async setChatMessageRead(conversationID) {
      this.hasNewMessage = false;
      await this.chat.setMessageRead({
        conversationID,
      });
    },

    tryStartGame(messageList) {
      messageList.forEach((message) => {
        if (message.type === TIM.TYPES.MSG_CUSTOM) {
          const data = JSON.parse(message.payload.data);
          if (data.action === 'text_tips') {
            switch (data.json.type) {
              case 'game_dice_start':
                this.startDiceGame(data.json.extra.game_id);
                break;
              case 'game_cycles_start':
                this.startCyclesGame(data.json.extra.game_id);
                break;
            }
          }
        }
      });
    },

    startDiceGame(gameId) {
      const num = random(1, 6);
      reportDice({
        game_id: gameId,
        robot_id: this.currentLoginRobotInfo.user_id,
        num,
      }).then(() => {
        this.sendMessage(
          'custom',
          {
            data: JSON.stringify({
              action: 'game',
              info: '',
              json: {
                game_id: gameId,
                num,
                type: 1,
              },
            }),
            description: '',
            extension: '',
          },
          {
            type: 'game',
          },
        );
      });
    },

    startCyclesGame(gameId) {
      const num = random(1, 3);
      reportCycles({
        game_id: gameId,
        robot_id: this.currentLoginRobotInfo.user_id,
        num,
      }).then(() => {
        this.sendMessage(
          'custom',
          {
            data: JSON.stringify({
              action: 'game',
              info: '',
              json: {
                game_id: gameId,
                num,
                type: 2,
              },
            }),
            description: '',
            extension: '',
          },
          {
            type: 'game',
          },
        );
      });
    },

    handleRevokeMessage(message) {
      this.chat
        .revokeMessage(message)
        .then(() => {
          this.$message.success('消息撤回成功');
        })
        .catch(() => {
          this.$message.error('消息撤回失败');
        });
    },

    handleDeleteMessage(message) {
      this.chat
        .deleteMessage([message])
        .then(() => {
          this.deleteLocalMessageFromMessageList(
            message.ID,
            this.selectedUserInfo.messageList,
          );
          this.$message.success('删除消息成功');
        })
        .catch(() => {
          this.$message.error('删除消息失败');
        });
    },

    deleteLocalMessageFromAllUser(messageId) {
      for (const userInfo of this.selectedUserInfoList) {
        this.deleteLocalMessageFromMessageList(messageId, userInfo.messageList);
      }
    },

    deleteLocalMessageFromMessageList(messageId, messageList) {
      const index = messageList.findIndex((item) => item.ID === messageId);
      if (index !== -1) {
        messageList.splice(index, 1);
      }
    },

    showChatLog(fromUserId, toUserId) {
      window.parent.postMessage(
        {
          channel: 'chat',
          type: 'showChatLog',
          data: {
            fromUserId,
            toUserId,
          },
        },
        '*',
      );
    },
  },
};
</script>

<style scoped lang="scss">
.home {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url('@/images/home/bg3.jpg') center center / cover no-repeat;
}
.window {
  position: relative;
  flex: none;
  display: flex;
  width: 1300px;
  height: 680px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0 11px 20px rgba(0, 0, 0, 0.3);
}
.navbar {
  display: flex;
  flex-direction: column;
  flex: none;
  width: 60px;
  padding: 56px 0 17px;
  border-radius: 12px 0px 0px 12px;
  background: #e8e8e9;
}
.navbar-list {
  display: flex;
  flex-direction: column;
}
.navbar-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px;
  font-size: 20px;
  cursor: pointer;
  &.selected {
    color: var(--color-primary);
    background: #ddd;
  }
}

.main {
  flex: 1;
  overflow: hidden;
  display: flex;
  background-color: #fff;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
}

.robot {
  display: flex;
  width: 100%;
}
.robot-left {
  display: flex;
  flex-direction: column;
  flex: none;
  width: 280px;
}
.robot-toolbar {
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  height: 55px;
  border-bottom: 1px solid #f4f5f9;
  background: #fff;
  font-size: 14px;
}
.robot-list {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
}
.robot-item {
  display: flex;
  align-items: center;
  padding: 12px;
  cursor: pointer;

  &:hover,
  &.selected {
    background: var(--color-primary-100);
  }
}
.robot-item-avatar {
  display: flex;
  width: 36px;
  height: 36px;
  border-radius: 6px;
}
.robot-item-body {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding-left: 8px;
  justify-content: space-between;
}
.robot-item-name {
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.robot-item-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 12px;
  line-height: 16px;
  color: #bbb;
  white-space: nowrap;
}
.robot-right {
  flex: 1;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid #e9eaf1;
}
.robot-base-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  ::v-deep .el-descriptions__table {
    width: auto;
  }
}
.robot-base-info-controls {
  display: flex;
  gap: 10px;
  margin-top: 20px;

  ::v-deep .el-button {
    margin: 0;
  }
}
.robot-unselected {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}
.conversation {
  display: flex;
  width: 100%;
}
.conversation-left {
  display: flex;
  flex-direction: column;
  flex: none;
  width: 280px;
}
.conversation-right {
  flex: 1;
  overflow: hidden;
  display: flex;
  border-left: 1px solid #e9eaf1;
}
.conversation-unselected {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}
.chat {
  position: relative;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #e9eaf1;
  border-right: 1px solid #e9eaf1;
}
.chat-header {
  display: flex;
  height: 50px;
  padding: 10px;
  align-items: center;
  border-bottom: 1px solid #e9eaf1;
}
.chat-title {
  flex: 1;
  font-size: 16px;
  line-height: 30px;
  font-weight: 500;
  color: #000;
  letter-spacing: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.chat-stat {
  margin-left: auto;
  margin-right: 10px;
  font-size: 12px;
  line-height: 1.25;
}
.chat-follow {
  display: flex;
  justify-self: center;
  column-gap: 5px;
  font-size: 12px;
}
.chat-message {
  position: relative;
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

.chat-footer {
  position: relative;
  flex: none;
  border-top: 1px solid #e9eaf1;
}
.chat-input-toolbar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.chat-input-toolbar-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  font-size: 18px;
  cursor: pointer;

  img {
    display: flex;
    width: 20px;
    height: 20px;
    object-fit: contain;
  }
}
.chat-input {
  display: block;
  width: 100%;
  height: 104px;
  padding: 0 10px;
  font-size: 14px;
  font-weight: 400;
  color: #01071a;
  resize: none;
  border: none;
  outline: none;
}
</style>
