<template>
  <div class="message-audio" :class="`flow-${flow}`" @click="toggle">
    <i v-show="playing" class="el-icon-video-pause message-audio-icon"></i>
    <i v-show="!playing" class="el-icon-video-play message-audio-icon"></i>
    <div class="message-audio-line-container">
      <div class="message-audio-line-wrapper">
        <div
          v-for="(i, index) in decibel"
          :key="index"
          :style="{ height: (i / 100) * 14 + 6 + 'px' }"
          class="message-audio-line"
        ></div>
      </div>

      <div
        class="message-audio-line-wrapper message-audio-line-wrapper-active"
        :style="{ width: progress + '%' }"
      >
        <div
          v-for="(i, index) in decibel"
          :key="index"
          :style="{ height: (i / 100) * 14 + 6 + 'px' }"
          class="message-audio-line message-audio-line-active"
        ></div>
      </div>

      <div
        class="message-audio-cursor"
        v-show="playing"
        :style="{
          left: progress + '%',
        }"
      ></div>
    </div>
    <div class="message-audio-duration">{{ second }}s</div>
  </div>
</template>

<script>
let currentVueAudio = null;

export default {
  props: {
    url: {
      type: String,
      default: '',
    },
    cloudCustomData: {
      type: String,
      default: '{}',
    },
    second: {
      type: Number,
      default: 0,
    },
    flow: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      progress: 0,
      playing: false,
      audio: null,
      decibel: null,
    };
  },
  watch: {
    cloudCustomData: {
      handler() {
        this.decibel = JSON.parse(this.cloudCustomData).cloud_data?.decibel || [];
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    if (this.audio) {
      this.audio.pause();
    }
  },
  methods: {
    toggle() {
      if (currentVueAudio && currentVueAudio !== this) {
        currentVueAudio.reset();
      }
      if (!this.audio) {
        this.createAndPlayAudio();
      } else if (this.playing) {
        this.audio.currentTime = 0;
        this.audio.pause();
      } else {
        this.audio.currentTime = 0;
        this.audio.play();
      }
      currentVueAudio = this;
    },
    createAndPlayAudio() {
      this.audio = new Audio(this.url);
      this.audio.addEventListener('play', () => {
        this.playing = true;
      });
      this.audio.addEventListener('pause', () => {
        this.playing = false;
        this.audio.currentTime = 0;
      });
      this.audio.addEventListener('timeupdate', () => {
        this.progress = (this.audio.currentTime / this.audio.duration) * 100;
      });
      this.audio.play();
    },
    reset() {
      if (this.audio) {
        this.audio.currentTime = 0;
        this.audio.pause();
      }
      this.progress = 0;
    },
  },
};
</script>

<style scoped lang="scss">
.message-audio {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  min-height: 45px;
  font-size: 14px;
  color: #464b4b;
  background: #f5f9f9;
  border-radius: 4px 12px 12px 12px;
  cursor: pointer;

  &.flow-out {
    color: #ffffff;
    background: var(--color-primary);
    border-radius: 12px 4px 12px 12px;
  }
}
.message-audio-icon {
  display: flex;
  margin-right: 8px;
  font-size: 20px;
}
.message-audio-line-container {
  position: relative;
}
.message-audio-line-wrapper {
  display: flex;
  align-items: center;
  column-gap: 2px;
}
.message-audio-line {
  width: 2px;
  height: 14px;
  flex: none;
  background-color: currentColor;
  opacity: 0.5;
}
.message-audio-line-wrapper-active {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}
.message-audio-line-active {
  opacity: 1;
}
.message-audio-cursor {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 2px;
  height: 40px;
  border-radius: 50%;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.4) 15%,
    #000 44%,
    rgba(0, 0, 0, 0.4) 79%,
    rgba(0, 0, 0, 0) 100%
  );
  .message-audio.flow-out & {
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.4) 15%,
      #fff 44%,
      rgba(255, 255, 255, 0.4) 79%,
      rgba(255, 255, 255, 0) 100%
    );
  }
}
.message-audio-duration {
  margin-left: 8px;
  font-size: 12px;
}
</style>
