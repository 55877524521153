<template>
  <div class="message-share" @click="handleClick">
    <div class="message-share-body">
      <img class="message-share-img" :src="img" />
      <div class="message-share-text">
        <div class="message-share-title">{{ title }}</div>
        <div class="message-share-link">立即查看</div>
      </div>
    </div>
    <div class="message-share-footer">
      <div>来自{{ from }}</div>
      <div>{{ type }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    img: {
      type: String,
      default: "",
    },
    url: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    from: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
  },
  methods: {
    handleClick() {
      window.open(this.url, "_blank");
    },
  },
};
</script>

<style scoped lang="scss">
.message-share {
  display: flex;
  flex-direction: column;
  width: 219px;
  height: 134px;
  background: #f5f9f9;
  border-radius: 4px 12px 12px 12px;
  cursor: pointer;
}
.message-share-body {
  display: flex;
  padding: 12px 12px 16px;
}
.message-share-img {
  display: block;
  width: 64px;
  height: 64px;
  object-fit: cover;
  border-radius: 8px;
}
.message-share-text {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  margin-left: 12px;
}
.message-share-title {
  font-size: 15px;
  color: #464b4b;
  line-height: 21px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  word-break: break-all;
}
.message-share-link {
  margin-top: auto;
  font-size: 12px;
  color: var(--color-primary);
}
.message-share-footer {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 12px;
  line-height: 17px;
  font-size: 12px;
  color: #9fa7a7;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 12px;
    right: 12px;
    height: 1px;
    background: #eef2f2;
  }
}
</style>
<!-- 
{
  "data": {
      "action": "h5_share",
      "json": {
          "appTitle": "扫脸领红包，寻找心动crush！",
          "appUrl": "/paipai",
          "description": "现在体验，100%中奖！红包，会员等福利送不停。",
          "img": "https://image.nnwmkj.com/common/web/share/paipai-share-thumb.png",
          "needInside": 1,
          "needOutside": 1,
          "nickname": "拍拍交友",
          "title": "最IN社交新玩法，3秒找到心动crush。",
          "updates_id": "",
          "url": "http://h5.dev.cdn.pique.nnwmkj.com/paipai"
      }
  },
  "description": "",
  "extension": ""
} -->

<!-- {
  "data": {
      "info": "",
      "action": "topic",
      "json": {
          "img": "https://peique-1318387147.cos.ap-guangzhou.myqcloud.com/dev/public/2024/01/19/45BdKicxJDKneAf7uksJORq7i6F9ZRvhqo4pztZ5.png",
          "updates_id": "4771",
          "needOutside": 1,
          "appUrl": "",
          "outSideList": [],
          "url": "http://h5.dev.cdn.pique.nnwmkj.com/shareTalk?updates_id=4771",
          "title": "官方跳转",
          "description": "来自皮雀雀的皮雀贴",
          "needInside": 1,
          "nickname": "皮雀雀"
      }
  },
  "description": "",
  "extension": ""
} -->
