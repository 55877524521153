<template>
  <Transition name="fade">
    <div v-if="visible" class="snapchat-video-viewer">
      <video
        :src="url"
        ref="videoRef"
        disablepictureinpicture
        @timeupdate="handleTimeupdate"
        @loadedmetadata="handleLoadedmetadata"
        @ended="handleEnded"
      ></video>
      <div class="controls-shadow">
        <div class="currentTime time">
          {{ formatVideoDuration(currentTime) }}
        </div>
        <div class="progress">
          <div class="progress-inner" :style="{ width: progress + '%' }"></div>
        </div>
        <div class="duration time">{{ formatVideoDuration(duration) }}</div>
      </div>
      <i class="el-icon-circle-close close" @click="handleClose"></i>

      <div v-if="showCountdown" class="countdown">播放完销毁</div>
    </div>
  </Transition>
</template>

<script>
import { formatVideoDuration } from '@/utils/index';

export default {
  props: {
    url: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      progress: 0,
      currentTime: 0,
      duration: 0,
      showCountdown: false,
      visible: false,
      timer: null,
    };
  },
  beforeDestroy() {
    this.$refs.videoRef?.pause();
    this.clearTimer();
  },
  methods: {
    formatVideoDuration,
    open(showCountdown) {
      this.visible = true;
      this.showCountdown = showCountdown;
      this.$nextTick(() => {
        const video = this.$refs.videoRef;
        if (video) {
          this.progress = 0;
          video.currentTime = 0;
          video.play();
        }
      });
    },
    handleTimeupdate() {
      const video = this.$refs.videoRef;
      this.currentTime = video.currentTime;
      this.progress = (video.currentTime / video.duration) * 100;
    },
    handleLoadedmetadata() {
      this.duration = this.$refs.videoRef.duration;
    },
    handleEnded() {
      this.timer = setTimeout(() => {
        this.clearTimer();
        this.visible = false;
      }, 1000);
    },
    handleClose() {
      this.clearTimer();
      this.visible = false;
    },
    clearTimer() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.snapchat-video-viewer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2147483647;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;

  video {
    width: 100%;
    height: 100%;
  }

  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 30px;
    color: #fff;
    cursor: pointer;
  }
  .countdown {
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    text-shadow: 0 0 5px #000;
  }
}

.controls-shadow {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  height: 60px;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.5)
  );
}

.progress {
  flex: 1;
  height: 4px;
  border-radius: 9999px;
  background-color: rgba(255, 255, 255, 0.3);
}
.progress-inner {
  height: 100%;
  border-radius: inherit;
  background-color: rgba(255, 255, 255, 1);
}

.time {
  margin: 0 20px;
  color: #fff;
  font-size: 14px;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
