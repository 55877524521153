export const constellationOptons = [
  { value: 1, label: "水瓶座" },
  { value: 2, label: "双鱼座" },
  { value: 3, label: "白羊座" },
  { value: 4, label: "金牛座" },
  { value: 5, label: "双子座" },
  { value: 6, label: "巨蟹座" },
  { value: 7, label: "狮子座" },
  { value: 8, label: "处女座" },
  { value: 9, label: "天秤座" },
  { value: 10, label: "天蝎座" },
  { value: 11, label: "射手座" },
  { value: 12, label: "摩羯座" },
];

export const relationshipStatusOptions = [
  { value: 1, label: "单身" },
  { value: 2, label: "恋爱中" },
  { value: 3, label: "想交友" },
  { value: 4, label: "想恋爱" },
  { value: 5, label: "已婚" },
  { value: 6, label: "离异" },
  { value: 7, label: "不想透露" },
];

export const genderOptions = [
  { value: 1, label: "男" },
  { value: 2, label: "女" },
  { value: 0, label: "未知" },
];

export const initiatorOptions = [
  { value: 1, label: "马甲主动发起" },
  { value: 0, label: "用户发起" },
];

export const letterList = [
  "*",
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
  "#",
];
