<template>
  <el-popover placement="top" trigger="click" popper-class="p-0" ref="popover">
    <div class="emoji-container">
      <div class="emoji-list">
        <div
          class="emoji-list-item"
          v-for="(item, index) in emojiImageList"
          :key="item"
          @click="handleClick(index)"
        >
          <img :src="require(`@/icons/emoji/${item}.png`)" />
        </div>
      </div>
    </div>
    <template #reference>
      <slot></slot>
    </template>
  </el-popover>
</template>

<script>
import { emojiImageList, emojiList } from "@/utils/emoji";

export default {
  data() {
    return {
      emojiImageList,
    };
  },
  methods: {
    handleClick(index) {
      this.$emit("select", emojiList[index]);
      this.doClose();
    },
    doClose() {
      this.$refs.popover.doClose();
    },
    doShow() {
      this.$refs.popover.doShow();
    },
  },
};
</script>

<style scoped lang="scss">
.emoji-container {
  padding: 10px 0 10px 10px;
}
.emoji-list {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  width: 490px;
  height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}
.emoji-list-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  img {
    display: flex;
    width: 26px;
    height: 26px;
  }
}
</style>
