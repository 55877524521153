<template>
  <el-popover placement="top" trigger="click" popper-class="p-0" ref="popover">
    <div class="game-list">
      <div class="game-item" @click="handleDiceClick">
        <img src="@/images/home/game/ic_chat_yaoshaizi@2x.png" />
        <span>摇骰子</span>
      </div>
      <div class="game-item" @click="handleCyclesClick">
        <img src="@/images/home/game/ic_chat_caiquan@2x.png" />
        <span>猜拳</span>
        <i v-if="createCyclesLoading" class="el-icon-loading"></i>
      </div>
      <div class="game-item" @click="handleTruthClick">
        <img src="@/images/home/game/ic_chat_zhenxinhua@2x.png" />
        <span>真心话</span>
        <i v-if="createTruthLoading" class="el-icon-loading"></i>
      </div>
      <div class="game-item" @click="handleDareClick">
        <img src="@/images/home/game/ic_chat_damaoxian@2x.png" />
        <span>大冒险</span>
        <i v-if="createDareLoading" class="el-icon-loading"></i>
      </div>
    </div>
    <template #reference>
      <slot></slot>
    </template>

    <DiceQuestionDialog
      :visible.sync="diceDialogVisible"
      :robotId="robotId"
      :friendId="friendId"
      :gameId="diceGameId"
      @invite="handleInvite"
    />
  </el-popover>
</template>

<script>
import DiceQuestionDialog from '@/components/DiceQuestionDialog';
import {
  createDiceGame,
  getDiceQa,
  createCyclesGame,
  sendDareMessage,
  sendTruthMessage,
} from '@/api/chat';

export default {
  components: {
    DiceQuestionDialog,
  },
  props: {
    friendId: {
      type: Number,
      required: true,
    },
    robotId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      diceGameId: null,
      diceDialogVisible: false,
      createDiceLoading: false,

      createCyclesLoading: false,
      createTruthLoading: false,
      createDareLoading: false,
    };
  },
  created() {},
  methods: {
    doClose() {
      this.$refs.popover.doClose();
    },
    doShow() {
      this.$refs.popover.doShow();
    },
    async handleDiceClick() {
      if (this.createDiceLoading) {
        return;
      }
      this.createDiceLoading = true;
      createDiceGame({
        friend_id: this.friendId,
        robot_id: this.robotId,
      })
        .then((res) => {
          if (res.data.created) {
            this.diceGameId = res.data.id;
            getDiceQa({
              friend_id: this.friendId,
              robot_id: this.robotId,
              game_id: this.diceGameId,
            }).then(() => {
              this.diceDialogVisible = true;
            });
          } else {
            this.$message.warning(
              `你已发起游戏，${res.data.count_down}分钟后再重新发起吧~`,
            );
          }
        })
        .finally(() => {
          this.createDiceLoading = false;
        });
    },

    handleInvite() {
      this.diceDialogVisible = false;
      this.doClose();
    },

    handleCyclesClick() {
      if (this.createCyclesLoading) {
        return;
      }
      this.createCyclesLoading = true;
      createCyclesGame({
        friend_id: this.friendId,
        robot_id: this.robotId,
      })
        .then((res) => {
          if (res.data.created) {
            this.doClose();
          } else {
            this.$message.warning(
              `你已发起游戏，${res.data.count_down}分钟后再重新发起吧~`,
            );
          }

          setTimeout(() => {
            this.createCyclesLoading = false;
          }, 500);
        })
        .catch(() => {
          this.createCyclesLoading = false;
        });
    },

    handleTruthClick() {
      if (this.createTruthLoading) {
        return;
      }
      this.createTruthLoading = true;
      sendTruthMessage({
        friend_id: this.friendId,
        robot_id: this.robotId,
      })
        .then(() => {
          this.doClose();
          setTimeout(() => {
            this.createTruthLoading = false;
          }, 500);
        })
        .catch(() => {
          this.createTruthLoading = false;
        });
    },

    handleDareClick() {
      if (this.createDareLoading) {
        return;
      }
      this.createDareLoading = true;
      sendDareMessage({
        friend_id: this.friendId,
        robot_id: this.robotId,
      })
        .then(() => {
          this.doClose();
          setTimeout(() => {
            this.createDareLoading = false;
          }, 500);
        })
        .catch(() => {
          this.createDareLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.game-list {
  display: flex;
  column-gap: 8px;
  padding: 8px 16px;
}
.game-item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 5px 8px;
  background: #f5f9f9;
  border-radius: 8px;
  cursor: pointer;
  img {
    width: 18px;
    height: 18px;
  }
  span {
    margin-left: 6px;
    font-size: 12px;
    color: #9fa7a7;
  }
}
</style>
