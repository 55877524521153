<template>
  <div class="message-list">
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.message-list {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}
</style>
