<template>
  <div
    v-if="!hidden"
    class="message"
    :class="`flow-${message.flow}`"
    :id="message.ID"
  >
    <div v-if="sendTime" class="message-timestamp">
      {{ sendTime | calendar }}
    </div>
    <div class="message-main">
      <div v-if="isFullMsg" class="message-full">
        <slot></slot>
      </div>
      <div v-else class="message-bubble">
        <div class="message-bubble-content">
          <div class="message-avatar">
            <img :src="message.avatar" />
          </div>
          <div class="message-body">
            <div v-if="nickname" class="message-nickname">{{ nickname }}</div>
            <div class="message-content" @contextmenu="handleContextmenu">
              <slot></slot>
              <ContextMenu
                ref="contextMenu"
                :list="contextMenuList"
                @select="handleSelect"
              />
            </div>
          </div>
          <div class="message-footer">
            <i v-if="status === 'loading'" class="el-icon-loading"></i>
            <i
              v-else-if="status === 'error'"
              class="text-xl text-red-500 el-icon-warning"
            ></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContextMenu from '@/components/ContextMenu';
import TIM from 'tim-js-sdk';

export default {
  components: {
    ContextMenu,
  },

  props: {
    message: {
      type: Object,
      required: true,
    },
    previousMessage: {
      type: Object,
      default: null,
    },
    showTimestamp: {
      type: Boolean,
      default: false,
    },
    nickname: {
      type: String,
      default: '',
    },
    isFullMsg: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      contextMenuList: [],
      canRevokeMinutes: 2,
      canShowTimeMinutes: 2,
      status: 'initial',
    };
  },
  computed: {
    sendTime() {
      if (
        this.previousMessage &&
        this.message.time - this.previousMessage.time >
          this.canShowTimeMinutes * 60
      ) {
        return this.message.time;
      }
    },
    hidden() {
      if (this.message.type === TIM.TYPES.MSG_CUSTOM) {
        const actions = ['signal_popup'];
        if (actions.includes(this.message.payload.data.action)) {
          return true;
        }
      }
    },
  },
  created() {
    this.$bus.$on(this.message.ID, this.messageEventHandler);
  },
  beforeDestroy() {
    this.$bus.$off(this.message.ID, this.messageEventHandler);
  },
  methods: {
    messageEventHandler({ type, status }) {
      if (type === 'status') {
        this.status = status;
      }
    },

    getContextMenu() {
      const contextMenu = [
        // { key: 'copy', title: '复制', icon: 'copy' },
      ];
      if (Date.now() / 1000 - this.message.time < this.canRevokeMinutes * 60) {
        contextMenu.push({ key: 'revoke', title: '撤回', icon: 'revoke' });
      }
      contextMenu.push({ key: 'delete', title: '删除', icon: 'delete' });
      return contextMenu;
    },
    handleContextmenu(event) {
      this.contextMenuList = this.getContextMenu();

      this.$refs.contextMenu.show(event);
    },
    handleSelect(item) {
      switch (item.key) {
        case 'copy':
          this.$message.success('复制成功');
          break;
        case 'revoke':
          this.$emit('revoke');
          break;
        case 'delete':
          this.$confirm('确认删除？', '提示', {
            type: 'warning',
          })
            .then(() => {
              this.$emit('delete');
            })
            .catch(() => {});
          break;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.message {
  padding: 16px 16px 0;
}
.message-timestamp {
  margin: 0 auto 8px;
  color: #9fa7a7;
  font-size: 12px;
  width: -webkit-fill-available;
  overflow-wrap: anywhere;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
}
.message-main {
  position: relative;
  display: flex;
  flex-direction: column;
}
.message-full {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.message-bubble {
  display: flex;
  flex-direction: column;
  margin-right: 36px;
}
.message-bubble-content {
  display: flex;
  justify-content: flex-start;
}
.message-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 5px;
  overflow: hidden;
  flex: 0 0 auto;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.message-body {
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 8px;
  max-width: calc(100% - 54px);
}
.message-nickname {
  margin-bottom: 4px;
  font-size: 12px;
  color: #999;
}
.message-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.message.flow-out {
  .message-bubble {
    margin-right: 0;
    margin-left: 36px;
  }
  .message-bubble-content {
    flex-direction: row-reverse;
  }
}
</style>
