<template>
  <video :src="url" controls></video>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped lang="scss">
video {
  width: 180px;
  height: 180px;
  background-color: #000;
}
</style>
