<template>
  <div class="message-image">
    <LimitImage :url="url" :width="width" :height="height" />
  </div>
</template>

<script>
import LimitImage from '@/components/LimitImage.vue'

export default {
  components: {
    LimitImage
  },
  props: {
    url: {
      type: String,
      default: '',
    },
    width: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style scoped lang="scss">
.message-image {
  display: flex;
}
</style>
