<template>
  <div class="message-location" @click="handleClick">
    <div class="message-location-header">
      <div class="message-location-name">{{ name }}</div>
      <div class="message-location-address">{{ address }}</div>
    </div>
    <div class="message-location-body">
      <img class="message-location-img" :src="img" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: '',
    },
    address: {
      type: String,
      default: '',
    },
    img: {
      type: String,
      default: '',
    },
    latitude: {
      type: [String, Number],
      default: '',
    },
    longitude: {
      type: [String, Number],
      default: '',
    },
  },
  methods: {
    handleClick() {
      const url = new URL(
        `https://api.map.baidu.com/marker`,
      );
      url.search = new URLSearchParams({
        location: `${this.latitude},${this.longitude}`,
        title: this.name,
        content: this.address,
        output: 'html',
        coord_type: 'bd09ll',
      });
      window.open(url, '_blank');
    },
  },
};
</script>

<style scoped lang="scss">
.message-location {
  width: 227px;
  height: 146px;
  background: #f5f9f9;
  border-radius: 4px 12px 12px 12px;
  overflow: hidden;
  cursor: pointer;
}

.message-location-header {
  padding: 8px 12px;
}
.message-location-name {
  font-weight: 400;
  font-size: 15px;
  color: #464b4b;
  line-height: 21px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.message-location-address {
  font-weight: 400;
  font-size: 12px;
  color: #9fa7a7;
  line-height: 17px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.message-location-body {
  height: 91px;
}
.message-location-img {
  display: flex;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
