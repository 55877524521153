let input;

export function chooseFiles(options = {}) {
  if (!input) {
    input = document.createElement('input');
    input.type = 'file';
    input.style.cssText =
      'position: fixed; z-index: -100; opacity: 0; pointer-events: none';
  }

  input.multiple = options.multiple;
  input.setAttribute('accept', options.accept);

  return new Promise((resolve) => {
    input.onchange = () => {
      const files = [...input.files] || [];
      resolve(files);
      input.value = '';
    };

    input.click();
  });
}

export function getFileType(urlOrFile) {
  let url = '';
  if (typeof urlOrFile === 'string') {
    url = urlOrFile;
  } else if (urlOrFile instanceof File) {
    if (urlOrFile.type) {
      return urlOrFile.type.split('/')[0];
    }
    url = urlOrFile.name;
  }
  const suffix = url.replace(/\?.*$/, '').match(/\.([a-zA-Z0-9]+)$/)?.[1] || '';
  if (/jpe?g|png|gif|webp|bmp/i.test(suffix)) {
    return 'image';
  }
  if (/avi|wmv|mpg|mpeg|mov|rm|ram|swf|flv|mp4/i.test(suffix)) {
    return 'video';
  }
  if (/mp3|wav|mid|aif|aiff|wma|ra|vqf|m4a|aac|midi|ogg|au|voc/i.test(suffix)) {
    return 'audio';
  }
  return suffix;
}

export function getImageSize(file) {
  return new Promise((resolve) => {
    const image = new Image();
    image.onload = () => {
      URL.revokeObjectURL(file);
      resolve({
        width: image.naturalWidth,
        height: image.naturalHeight,
      });
    };
    image.src = URL.createObjectURL(file);
  });
}

export function loadImage(url) {
  return new Promise((resolve, reject) => {
    let loaded = false;
    const img = new Image();
    img.onload = () => {
      if (!loaded) {
        loaded = true;
        resolve(img);
      }
    };
    img.onerror = () => {
      reject();
    };
    img.src = url;
    if (img.complete) {
      loaded = true;
      resolve(img);
    }
  });
}
