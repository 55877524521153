<template>
  <el-dialog
    :visible.sync="innerVisible"
    :append-to-body="true"
    width="600px"
    title="本局摇骰子规则"
    top="5vh"
  >
    <div class="question-list">
      <div v-for="item in questions" :key="item.id" class="question-item">
        点数和=
        <span>{{ item.value }}</span>
        {{ item.question }}
      </div>
    </div>
    <div class="question-tips">
      <i class="el-icon-question"></i>
      系统会为你们各掷一次骰子，依照两骰子朝上面的点数之和进行游戏。点击「换一组」生成新规则
    </div>

    <el-alert
      v-if="over"
      class="mt-3"
      title="本局游戏已结束"
      type="info"
      center
      show-icon
      :closable="false"
    />
    
    <template
      v-if="(isInitiator && !isInvite) || (!isInitiator && status === 1)"
      #footer
    >
      <template v-if="isInitiator && !isInvite">
        <el-button :loading="questionLoading" @click="getDiceQuestionData">
          换一组
        </el-button>
        <el-button :loading="inviteLoading" type="primary" @click="inviteDice">
          邀请游戏
        </el-button>
      </template>
      <el-button
        v-if="!isInitiator && status === 1"
        :loading="startLoading"
        type="primary"
        @click="startGame"
      >
        开始游戏
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
import {
  getDiceQa,
  inviteDiceGame,
  getDiceGameInfo,
  startDiceGame,
} from '@/api/chat';

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    robotId: {
      type: Number,
      required: true,
    },
    friendId: {
      type: Number,
      required: true,
    },
    gameId: {
      type: [Number, String],
    },
  },
  data() {
    return {
      questionLoading: false,
      inviteLoading: false,
      startLoading: false,
      isInvite: false,
      isInitiator: false,
      status: 0,
      questions: [],
    };
  },
  watch: {
    innerVisible: {
      handler() {
        if (this.innerVisible && !this.questionLoading) {
          this.getDiceGameInfoData();
        }
      },
      immediate: true,
    },
  },
  computed: {
    innerVisible: {
      get() {
        return this.visible;
      },
      set(newValue) {
        this.$emit('update:visible', newValue);
      },
    },
    over() {
      // 1=创建，2=开始，3=结束，4=过期
      return [3, 4].includes(this.status);
    },
  },
  methods: {
    getDiceGameInfoData() {
      this.questionLoading = true;
      getDiceGameInfo({
        game_id: this.gameId,
      })
        .then(({ data }) => {
          this.questions = data.qa_list;
          this.status = data.status;
          this.isInvite = data.is_invite === 1;
          this.isInitiator = data.user_id === this.robotId;
        })
        .finally(() => {
          this.questionLoading = false;
        });
    },

    getDiceQuestionData() {
      this.questionLoading = true;
      getDiceQa({
        friend_id: this.friendId,
        robot_id: this.robotId,
        game_id: this.gameId,
      })
        .then((res) => {
          this.questions = res.data.list;
        })
        .finally(() => {
          this.questionLoading = false;
        });
    },

    inviteDice() {
      this.inviteLoading = true;
      inviteDiceGame({
        friend_id: this.friendId,
        robot_id: this.robotId,
        game_id: this.gameId,
      })
        .then(() => {
          this.$emit('invite');
        })
        .finally(() => {
          this.inviteLoading = false;
        });
    },

    startGame() {
      this.startLoading = true;
      startDiceGame({
        robot_id: this.robotId,
        game_id: this.gameId,
      })
        .then(() => {
          this.$emit('start');
        })
        .finally(() => {
          this.startLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.question-list {
  display: flex;
  flex-direction: column;
}
.question-item {
  padding: 10px 12px;
  border-radius: 12px;
  font-size: 12px;
  line-height: 17px;
  color: #1f2626;
  background: #f5f9f9;

  &:not(:first-child) {
    margin-top: 8px;
  }

  span {
    margin-right: 18px;
    font-weight: bold;
    font-size: 16px;
    color: var(--color-primary);
  }
}
.question-tips {
  margin-top: 12px;
  font-size: 12px;
  color: #464b4b;
  line-height: 20px;
}
</style>
