export const emojiList = [
  "\u{1f603}",
  "\u{1f600}",
  "\u{1f60a}",
  "\u{263a}",
  "\u{1f609}",
  "\u{1f60d}",
  "\u{1f618}",
  "\u{1f61a}",
  "\u{1f61c}",
  "\u{1f61d}",
  "\u{1f633}",
  "\u{1f601}",
  "\u{1f614}",
  "\u{1f60c}",
  "\u{1f61f}",
  "\u{1f61e}",
  "\u{1f623}",
  "\u{1f622}",
  "\u{1f602}",
  "\u{1f62d}",
  "\u{1f62a}",
  "\u{1f630}",
  "\u{1f605}",
  "\u{1f613}",
  "\u{1f62b}",
  "\u{1f629}",
  "\u{1f628}",
  "\u{1f631}",
  "\u{1f621}",
  "\u{1f624}",
  "\u{1f616}",
  "\u{1f606}",
  "\u{1f60b}",
  "\u{1f637}",
  "\u{1f60e}",
  "\u{1f634}",
  "\u{1f632}",
  "\u{1f62e}",
  "\u{1f608}",
  "\u{1f47f}",
  "\u{1f62f}",
  "\u{1f62c}",
  "\u{1f615}",
  "\u{1f635}",
  "\u{1f636}",
  "\u{1f607}",
  "\u{1f60f}",
  "\u{1f611}",
  "\u{1f648}",
  "\u{1f649}",
  "\u{1f64a}",
  "\u{1f47d}",
  "\u{1f4a9}",
  "\u{2764}",
  "\u{1f494}",
  "\u{1f525}",
  "\u{1f4a2}",
  "\u{1f4a4}",
  "\u{1f6ab}",
  "\u{2b50}",
  "\u{26a1}",
  "\u{1f319}",
  "\u{2600}",
  "\u{26c5}",
  "\u{2601}",
  "\u{2744}",
  "\u{2614}",
  "\u{26c4}",
  "\u{1f44d}",
  "\u{1f44e}",
  "\u{1f44c}",
  "\u{1f44a}",
  "\u{270a}",
  "\u{270c}",
  "\u{270b}",
  "\u{1f64f}",
  "\u{261d}",
  "\u{1f44f}",
  "\u{1f91d}",
  "\u{1f4aa}",
  "\u{1f46a}",
  "\u{1f46b}",
  "\u{1f47c}",
  "\u{1f434}",
  "\u{1f436}",
  "\u{1f437}",
  "\u{1f47b}",
  "\u{1f339}",
  "\u{1f33b}",
  "\u{1f332}",
  "\u{1f384}",
  "\u{1f381}",
  "\u{1f389}",
  "\u{1f4b0}",
  "\u{1f382}",
  "\u{1f356}",
  "\u{1f35a}",
  "\u{1f366}",
  "\u{1f36b}",
  "\u{1f349}",
  "\u{1f377}",
  "\u{1f37b}",
  "\u{2615}",
  "\u{1f3c0}",
  "\u{26bd}",
  "\u{1f3c2}",
  "\u{1f6b2}",
  "\u{1f3a4}",
  "\u{1f3b5}",
  "\u{1f3b2}",
  "\u{1f004}",
  "\u{1f451}",
  "\u{1f484}",
  "\u{1f48b}",
  "\u{1f48d}",
  "\u{1f4da}",
  "\u{1f393}",
  "\u{270f}",
  "\u{1f3e1}",
  "\u{1f6bf}",
  "\u{1f4a1}",
  "\u{1f4de}",
  "\u{1f4e2}",
  "\u{1f556}",
  "\u{23f0}",
  "\u{23f3}",
  "\u{1f4a3}",
  "\u{1f52b}",
  "\u{1f48a}",
  "\u{1f680}",
  "\u{1f30f}",
];

export const emojiEntity = [
  "&#x1f603",
  "&#x1f600",
  "&#x1f60a",
  "&#x263a",
  "&#x1f609",
  "&#x1f60d",
  "&#x1f618",
  "&#x1f61a",
  "&#x1f61c",
  "&#x1f61d",
  "&#x1f633",
  "&#x1f601",
  "&#x1f614",
  "&#x1f60c",
  "&#x1f61f",
  "&#x1f61e",
  "&#x1f623",
  "&#x1f622",
  "&#x1f602",
  "&#x1f62d",
  "&#x1f62a",
  "&#x1f630",
  "&#x1f605",
  "&#x1f613",
  "&#x1f62b",
  "&#x1f629",
  "&#x1f628",
  "&#x1f631",
  "&#x1f621",
  "&#x1f624",
  "&#x1f616",
  "&#x1f606",
  "&#x1f60b",
  "&#x1f637",
  "&#x1f60e",
  "&#x1f634",
  "&#x1f632",
  "&#x1f62e",
  "&#x1f608",
  "&#x1f47f",
  "&#x1f62f",
  "&#x1f62c",
  "&#x1f615",
  "&#x1f635",
  "&#x1f636",
  "&#x1f607",
  "&#x1f60f",
  "&#x1f611",
  "&#x1f648",
  "&#x1f649",
  "&#x1f64a",
  "&#x1f47d",
  "&#x1f4a9",
  "&#x2764",
  "&#x1f494",
  "&#x1f525",
  "&#x1f4a2",
  "&#x1f4a4",
  "&#x1f6ab",
  "&#x2b50",
  "&#x26a1",
  "&#x1f319",
  "&#x2600",
  "&#x26c5",
  "&#x2601",
  "&#x2744",
  "&#x2614",
  "&#x26c4",
  "&#x1f44d",
  "&#x1f44e",
  "&#x1f44c",
  "&#x1f44a",
  "&#x270a",
  "&#x270c",
  "&#x270b",
  "&#x1f64f",
  "&#x261d",
  "&#x1f44f",
  "&#x1f91d",
  "&#x1f4aa",
  "&#x1f46a",
  "&#x1f46b",
  "&#x1f47c",
  "&#x1f434",
  "&#x1f436",
  "&#x1f437",
  "&#x1f47b",
  "&#x1f339",
  "&#x1f33b",
  "&#x1f332",
  "&#x1f384",
  "&#x1f381",
  "&#x1f389",
  "&#x1f4b0",
  "&#x1f382",
  "&#x1f356",
  "&#x1f35a",
  "&#x1f366",
  "&#x1f36b",
  "&#x1f349",
  "&#x1f377",
  "&#x1f37b",
  "&#x2615",
  "&#x1f3c0",
  "&#x26bd",
  "&#x1f3c2",
  "&#x1f6b2",
  "&#x1f3a4",
  "&#x1f3b5",
  "&#x1f3b2",
  "&#x1f004",
  "&#x1f451",
  "&#x1f484",
  "&#x1f48b",
  "&#x1f48d",
  "&#x1f4da",
  "&#x1f393",
  "&#x270f",
  "&#x1f3e1",
  "&#x1f6bf",
  "&#x1f4a1",
  "&#x1f4de",
  "&#x1f4e2",
  "&#x1f556",
  "&#x23f0",
  "&#x23f3",
  "&#x1f4a3",
  "&#x1f52b",
  "&#x1f48a",
  "&#x1f680",
  "&#x1f30f",
];

export const emojiImageList = [
  "u1f603",
  "u1f600",
  "u1f60a",
  "u263a",
  "u1f609",
  "u1f60d",
  "u1f618",
  "u1f61a",
  "u1f61c",
  "u1f61d",
  "u1f633",
  "u1f601",
  "u1f614",
  "u1f60c",
  "u1f61f",
  "u1f61e",
  "u1f623",
  "u1f622",
  "u1f602",
  "u1f62d",
  "u1f62a",
  "u1f630",
  "u1f605",
  "u1f613",
  "u1f62b",
  "u1f629",
  "u1f628",
  "u1f631",
  "u1f621",
  "u1f624",
  "u1f616",
  "u1f606",
  "u1f60b",
  "u1f637",
  "u1f60e",
  "u1f634",
  "u1f632",
  "u1f62e",
  "u1f608",
  "u1f47f",
  "u1f62f",
  "u1f62c",
  "u1f615",
  "u1f635",
  "u1f636",
  "u1f607",
  "u1f60f",
  "u1f611",
  "u1f648",
  "u1f649",
  "u1f64a",
  "u1f47d",
  "u1f4a9",
  "u2764",
  "u1f494",
  "u1f525",
  "u1f4a2",
  "u1f4a4",
  "u1f6ab",
  "u2b50",
  "u26a1",
  "u1f319",
  "u2600",
  "u26c5",
  "u2601",
  "u2744",
  "u2614",
  "u26c4",
  "u1f44d",
  "u1f44e",
  "u1f44c",
  "u1f44a",
  "u270a",
  "u270c",
  "u270b",
  "u1f64f",
  "u261d",
  "u1f44f",
  "u1f91d",
  "u1f4aa",
  "u1f46a",
  "u1f46b",
  "u1f47c",
  "u1f434",
  "u1f436",
  "u1f437",
  "u1f47b",
  "u1f339",
  "u1f33b",
  "u1f332",
  "u1f384",
  "u1f381",
  "u1f389",
  "u1f4b0",
  "u1f382",
  "u1f356",
  "u1f35a",
  "u1f366",
  "u1f36b",
  "u1f349",
  "u1f377",
  "u1f37b",
  "u2615",
  "u1f3c0",
  "u26bd",
  "u1f3c2",
  "u1f6b2",
  "u1f3a4",
  "u1f3b5",
  "u1f3b2",
  "u1f004",
  "u1f451",
  "u1f484",
  "u1f48b",
  "u1f48d",
  "u1f4da",
  "u1f393",
  "u270f",
  "u1f3e1",
  "u1f6bf",
  "u1f4a1",
  "u1f4de",
  "u1f4e2",
  "u1f556",
  "u23f0",
  "u23f3",
  "u1f4a3",
  "u1f52b",
  "u1f48a",
  "u1f680",
  "u1f30f",
];
