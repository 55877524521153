<template>
  <div class="message-dare">
    <div class="message-dare-header">
      <img src="@/images/home/game/img_game_damaoxianzi.png" />
    </div>
    <div class="message-dare-body">
      {{ question }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    num: {
      type: Number,
      default: undefined,
    },
    question: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped lang="scss">
.message-dare {
  width: 248px;
  background: linear-gradient(to bottom, #e5fff8 0%, #d6fff4 100%);
  border-radius: 12px;

  img {
    display: block;
    width: 248px;
    height: 36px;
  }
}
.message-dare-body {
  padding: 0 12px 12px;
  font-size: 13px;
  color: #1f2626;
  line-height: 18px;
}
</style>
