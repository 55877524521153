<template>
  <div class="message-video">
    <LimitVideo :url="url" />
  </div>
</template>

<script>
import LimitVideo from '@/components/LimitVideo.vue';

export default {
  components: {
    LimitVideo,
  },
  props: {
    url: {
      type: String,
      default: '',
    },
  },
};
</script>
