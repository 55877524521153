<template>
  <div class="message-apply-album">
    <div class="message-apply-album-body">
      <img
        class="message-apply-album-img"
        src="@/images/home/contact/ic_xiaoxi_shenqingxiangce.png"
      />
      <div class="message-apply-album-text">
        <div class="message-apply-album-title">
          <span v-if="flow === 'in'">TA向你申请查看相册</span>
          <span v-else>你向TA申请查看相册</span>
        </div>
        <div class="message-apply-album-description">
          <span v-if="flow === 'in'">同意后TA可在有效期内查看</span>
          <span v-else>请耐心等待TA的回复哦</span>
        </div>
      </div>
    </div>
    <div v-if="flow === 'in'" class="message-apply-album-footer">
      <el-button
        class="flex-1"
        type="primary"
        size="small"
        round
        plain
        :disabled="disabled"
        :loading="refuseLoading"
        @click="onOperation(2)"
      >
        <span v-if="innerStatus === 2">已拒绝</span>
        <span v-else>拒绝</span>
      </el-button>
      <el-button
        class="flex-1"
        type="primary"
        size="small"
        round
        :disabled="disabled"
        :loading="agreeLoading"
        @click="onOperation(1)"
      >
        <span v-if="innerStatus === 1">已同意</span>
        <span v-else>同意</span>
      </el-button>
    </div>
  </div>
</template>

<script>
import { operatePrivateAlbumApplyRecord } from '@/api/chat';

export default {
  props: {
    // 1：同意，2：拒绝
    status: {
      type: Number,
      default: 0,
    },
    expireAt: {
      type: Number,
      default: 0,
    },
    flow: {
      type: String,
      default: 'in',
    },
    applyId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      refuseLoading: false,
      agreeLoading: false,
      innerStatus: this.status,
    };
  },
  computed: {
    isExpired() {
      return Date.now() > this.expireAt * 1000;
    },
    disabled() {
      return this.isExpired || this.innerStatus === 1 || this.innerStatus === 2;
    },
  },
  methods: {
    onOperation(status) {
      if (this.refuseLoading || this.agreeLoading) {
        return;
      }
      if (status === 1) {
        this.agreeLoading = true;
      } else {
        this.refuseLoading = true;
      }
      operatePrivateAlbumApplyRecord({
        record_id: this.applyId,
        status: status,
      })
        .then(() => {
          this.innerStatus = status;
        })
        .finally(() => {
          this.agreeLoading = false;
        this.refuseLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.message-apply-album {
  padding: 12px;
  border-radius: 12px;
  border: 2px solid #eef2f2;
}
.message-apply-album-body {
  display: flex;
}
.message-apply-album-img {
  display: flex;
  width: 48px;
  height: 48px;
}
.message-apply-album-text {
  margin-left: 8px;
}
.message-apply-album-title {
  font-weight: 500;
  font-size: 14px;
  color: #1f2626;
  line-height: 20px;
}
.message-apply-album-description {
  margin-top: 8px;
  font-weight: 400;
  font-size: 12px;
  color: #9fa7a7;
  line-height: 16px;
}
.message-apply-album-footer {
  display: flex;
  margin-top: 12px;
  margin-bottom: 4px;
}
</style>
