<template>
  <div class="user-info">
    <template v-if="data">
      <div class="user-info-header">
        <div class="user-info-title">{{ title }}</div>
        <el-button type="text" @click="handleRemarkClick">备注</el-button>
      </div>
      <div class="user-info-list">
        <div class="user-info-item">
          <span class="label">昵称：</span>
          <el-button
            type="text"
            size="mini"
            @click="showUserInfo(data.user_id)"
          >
            {{ data.nickname }}
          </el-button>
        </div>
        <div class="user-info-item">
          <span class="label">皮雀ID：</span>
          <el-button
            type="text"
            size="mini"
            @click="showUserInfo(data.user_id)"
          >
            {{ data.pic_id }}
          </el-button>
        </div>
        <div class="user-info-item">
          <span class="label">性别：</span>
          <span>
            {{ getLabelByValue(data.gender, genderOptions) }}
          </span>
        </div>
        <div class="user-info-item">
          <span class="label">年龄：</span>
          <span>{{ data.age }}岁</span>
        </div>
        <div class="user-info-item">
          <span class="label">星座：</span>
          <span>
            {{ getLabelByValue(data.constellation, constellationOptons) }}
          </span>
        </div>
        <div class="user-info-item">
          <span class="label">身高：</span>
          <span>{{ data.height }}cm</span>
        </div>
        <div class="user-info-item">
          <span class="label">体重：</span>
          <span></span>
          {{ data.weight }}kg
        </div>
        <div class="user-info-item">
          <span class="label">感情状态：</span>
          <span>
            {{
              getLabelByValue(
                data.relationship_status,
                relationshipStatusOptions,
              )
            }}
          </span>
        </div>
        <div class="user-info-item">
          <span class="label">关注数：</span>
          <span>{{ data.follower_num }}</span>
        </div>
        <div class="user-info-item">
          <span class="label">粉丝数：</span>
          <span>{{ data.fans_num }}</span>
        </div>
        <div class="user-info-item">
          <span class="label">TA喜欢：</span>
          <span>{{ data.like_num }}</span>
        </div>
        <div class="user-info-item">
          <span class="label">喜欢TA：</span>
          <span>{{ data.liked_num }}</span>
        </div>
        <div class="user-info-item">
          <span class="label">聊天：</span>
          <span>{{ data.chat_count }}</span>
        </div>
        <div class="user-info-item">
          <span class="label">身份：</span>
          <span>{{ data.is_vip ? 'VIP会员' : '普通会员' }}</span>
        </div>
        <div v-if="data.city !== undefined" class="user-info-item">
          <span class="label">城市：</span>
          <span>{{ data.city }}</span>
        </div>
        <div class="user-info-item">
          <span class="label">财富值等级：LV.</span>
          <span>{{ data.level.wealth.level }}</span>
        </div>
        <div class="user-info-item">
          <span class="label">魅力值等级：LV.</span>
          <span>{{ data.level.charm.level }}</span>
        </div>
        <div class="user-info-item">
          <span class="label">实名认证：</span>
          <span>{{ data.is_verified == 1 ? '已完成' : '未完成' }}</span>
        </div>
        <div class="user-info-item">
          <span class="label">真人认证：</span>
          <span>{{ data.is_real_verified == 1 ? '已完成' : '未完成' }}</span>
        </div>
        <div class="user-info-item">
          <span class="label">你的特质：</span>
          <span>{{ data.tags.special }}</span>
        </div>
        <div class="user-info-item">
          <span class="label">交友类型：</span>
          <span>{{ data.tags.friend }}</span>
        </div>
        <div class="user-info-item">
          <span class="label">日常爱好：</span>
          <span>{{ data.tags.hobby }}</span>
        </div>
        <div class="user-info-item">
          <span class="label">个性标签：</span>
          <span>{{ data.tags.personality }}</span>
        </div>
        <div v-if="data.source !== undefined" class="user-info-item">
          <span class="label">来源：</span>
          <span>{{ getLabelByValue(data.source, friendSourceOptions) }}</span>
          <span v-if="data.source === 6">{{ data.city }}</span>
        </div>
        <div class="user-info-item">
          <span class="label">类型：</span>
          <span>
            {{ getLabelByValue(data.is_initiator, initiatorOptions) }}
          </span>
        </div>
        <div class="user-info-item">
          <span class="label">账号状态：</span>
          <span>{{ data.punish_text }}</span>
        </div>
        <div class="user-info-item">
          <span class="label">在线状态：</span>
          <span>{{ data.is_online ? '当前活跃' : '不在线' }}</span>
        </div>
        <div class="user-info-item">
          <span class="label">备注：</span>
          <span>{{ isRobot ? userData.robot_remark : data.user_remark }}</span>
        </div>
      </div>
    </template>

    <el-dialog
      :visible.sync="remarkVisible"
      :append-to-body="true"
      :title="`${this.isRobot ? '马甲号' : '用户'}备注`"
    >
      <el-input v-model="remark" placeholder="请输入备注信息" type="textarea" />

      <template #footer>
        <el-button @click="remarkVisible = false">取消</el-button>
        <el-button
          type="primary"
          :loading="remarkLoading"
          @click="hancleRemarkConfirm"
        >
          确定
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { getLabelByValue } from '@/utils';
import {
  constellationOptons,
  relationshipStatusOptions,
  genderOptions,
  initiatorOptions,
} from '@/constant/common';
import { setRemark } from '@/api/chat';

export default {
  props: {
    data: {
      type: Object,
    },
    title: {
      type: String,
      default: '',
    },
    userData: {
      type: Object,
    },
    isRobot: {
      type: Boolean,
      default: false,
    },
    friendSourceOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      constellationOptons,
      relationshipStatusOptions,
      genderOptions,
      initiatorOptions,
      remarkVisible: false,
      remark: '',
      remarkLoading: false,
    };
  },
  methods: {
    getLabelByValue,

    showUserInfo(userId) {
      window.parent.postMessage(
        {
          channel: 'chat',
          type: 'showUserInfo',
          data: {
            userId,
          },
        },
        '*',
      );
    },

    handleRemarkClick() {
      this.remark = this.isRobot
        ? this.userData.robot_remark
        : this.data.user_remark;
      this.remarkVisible = true;
    },

    hancleRemarkConfirm() {
      this.remarkLoading = true;
      setRemark({
        chat_id: this.userData.chat_id,
        // 1=设置用户备注、2=设置马甲号备注
        type: this.isRobot ? 2 : 1,
        remark: this.remark,
      })
        .then(() => {
          this.$message.success('设置成功');
          this.$set(
            this.userData,
            this.isRobot ? 'robot_remark' : 'user_remark',
            this.remark,
          );
          this.remarkVisible = false;
        })
        .finally(() => {
          this.remarkLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.user-info {
  flex: none;
  display: flex;
  flex-direction: column;
  width: 220px;
}
.user-info-header {
  flex: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 12px;
  border-bottom: 1px solid #e9eaf1;
}
.user-info-title {
  font-size: 14px;
  font-weight: 500;
}
.user-info-list {
  flex: 1;
  padding: 12px;
  overflow-y: auto;
  overflow-x: hidden;

  ::v-deep .el-button--mini {
    padding: 2px 0;
  }
}
.user-info-item {
  padding-bottom: 6px;
  font-size: 13px;
  color: #222;

  .label {
    color: #999;
  }
}
</style>
