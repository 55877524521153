<template>
  <div class="shadow-lg new-msg-btn" @click="$emit('click')">
    <i class="el-icon-bottom"></i>
    有新消息
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.new-msg-btn {
  position: sticky;
  right: 20px;
  bottom: 20px;
  display: flex;
  align-items: center;
  padding: 8px 26px;
  margin-left: auto;
  border-radius: 18px;
  font-size: 14px;
  color: #ff860e;
  background: #fff;
  cursor: pointer;
}
</style>
