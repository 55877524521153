<template>
  <div
    class="message-cycles-choice"
    :class="{ over: status === 4, 'can-activate': canActivate }"
  >
    <div
      class="message-cycles-choice-item"
      @click="choice(3)"
      :class="{ selected: innerChoiceType === 3 }"
    >
      <img src="@/images/home/game/ic_game_popzhenxinhua.png" />
    </div>
    <div
      class="message-cycles-choice-item"
      @click="choice(4)"
      :class="{ selected: innerChoiceType === 4 }"
    >
      <img src="@/images/home/game/ic_game_popdamaoxian.png" />
    </div>
  </div>
</template>

<script>
import { getCyclesGameInfo, choiceCycles } from '@/api/chat';

export default {
  props: {
    gameId: {
      type: [Number, String],
      default: undefined,
    },
    robotId: {
      type: Number,
      required: true,
    },
    status: {
      type: Number,
      default: undefined,
    },
    choiceType: {
      type: [Number, String],
      default: -1,
    },
  },
  data() {
    return {
      detailLoading: false,
      choiceLoading: false,
      innerChoiceType: this.choiceType,
    };
  },
  computed: {
    canActivate() {
      return (
        // status 1=创建，2=开始，3=结束，4=过期
        // choiceType 1=骰子，2=猜拳，3=真心话，4=大冒险
        ![3, 4].includes(this.status) && ![3, 4].includes(this.innerChoiceType)
      );
    },
  },
  methods: {
    choice(type) {
      if (
        [3, 4].includes(this.status) ||
        [3, 4].includes(this.innerChoiceType) ||
        this.detailLoading ||
        this.choiceLoading
      ) {
        return;
      }

      this.detailLoading = true;
      getCyclesGameInfo({
        game_id: this.gameId,
      })
        .then(({ data }) => {
          if (data.status !== 2) {
            return;
          }
          this.choiceLoading = true;
          choiceCycles({
            game_id: this.gameId,
            robot_id: this.robotId,
            type,
          })
            .then(() => {
              this.innerChoiceType = type;
            })
            .finally(() => {
              this.choiceLoading = false;
            });
        })
        .finally(() => {
          this.detailLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.message-cycles-choice {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 343px;
  margin: 0 auto;
  padding: 18px;
  background: url('@/images/home/game/img_game_chengfa.png') center center /
    contain no-repeat;
  &.over {
    .message-cycles-choice-item {
      opacity: 0.5;
    }
  }
}
.message-cycles-choice-item {
  display: flex;
  width: 148px;
  height: 64px;
  .message-cycles-choice.can-activate & {
    cursor: pointer;
  }

  &.selected {
    opacity: 0.5;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
