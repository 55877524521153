<template>
  <el-popover
    placement="top"
    trigger="click"
    popper-class="p-0"
    ref="popover"
  >
    <div class="ip-emoji-container">
      <div class="ip-emoji-list">
        <div
          class="ip-emoji-list-item"
          v-for="item in list"
          :key="item.emoticon_id"
          @click="handleClick(item)"
        >
          <img :src="item.url" />
        </div>
      </div>
    </div>
    <template #reference>
      <slot></slot>
    </template>
  </el-popover>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {
    handleClick(item) {
      this.$emit("select", item);
      this.doClose();
    },
    doClose() {
      this.$refs.popover.doClose();
    },
    doShow() {
      this.$refs.popover.doShow();
    },
  },
};
</script>

<style scoped lang="scss">
.ip-emoji-container {
  padding: 10px 0 10px 10px;
}
.ip-emoji-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 410px;
  height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}
.ip-emoji-list-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  cursor: pointer;
  img {
    display: flex;
    width: 100%;
    height: 100%;
  }
}
</style>
