<template>
  <el-popover
    placement="right"
    trigger="hover"
    popper-class="p-0 ml-0"
    :close-delay="0.1"
  >
    <div class="robot-card">
      <template v-if="robotInfo">
        <img class="robot-card-img" :src="robotInfo.head_img" />
        <div class="robot-card-body">
          <div class="robot-card-title">
            {{ robotInfo.nickname }}
          </div>
          <div class="robot-card-description">
            皮雀ID: {{ robotInfo.pic_id }}
          </div>
        </div>
      </template>
      <div v-else class="robot-card-tips">未登录马甲号</div>
    </div>
    <template #reference>
      <div class="navbar-header">
        <div class="navbar-header-avatar">
          <img v-if="robotInfo" :src="robotInfo.head_img" />
        </div>
      </div>
    </template>
  </el-popover>
</template>

<script>
export default {
  props: {
    robotInfo: {
      type: Object,
    },
  },
};
</script>

<style scoped lang="scss">
.navbar-header {
  position: relative;
  padding: 12px;
}
.navbar-header-avatar {
  width: 36px;
  height: 36px;
  border-radius: 5px;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.1);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.robot-card {
  display: flex;
  align-items: center;
  width: 220px;
  padding: 14px 20px;
}
.robot-card-img {
  width: 36px;
  height: 36px;
  border-radius: 5px;
  object-fit: cover;
}
.robot-card-body {
  flex: 1;
  overflow: hidden;
  margin-left: 10px;
}
.robot-card-title {
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.robot-card-description {
  font-size: 12px;
  color: #999;
}
.robot-card-tips {
  font-size: 14px;
  font-weight: 500;
}
</style>
