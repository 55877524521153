<template>
  <div class="message-match-card">
    <div class="message-match-card-avatar-wrapper">
      <div class="message-match-card-avatar message-match-card-avatar-1">
        <img class="message-match-card-avatar-img" :src="avatar1" />
      </div>
      <div class="message-match-card-avatar message-match-card-avatar-2">
        <img class="message-match-card-avatar-img" :src="avatar2" />
      </div>
      <div class="message-match-card-link">
        <img
          class="message-match-card-link-img"
          src="@/images/home/contact/ic_xiaoxi_teshulianjie.png"
        />
      </div>
    </div>
    <div class="message-match-card-title">{{ title }}</div>
    <div class="message-match-card-subtitle">
      <i class="message-match-card-dot"></i>
      {{ subtitle }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    avatar1: {
      type: String,
      default: '',
    },
    avatar2: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.message-match-card {
  position: relative;
  width: 343px;
  height: 105px;
  margin: 0 auto;
  background: url('@/images/home/contact/img_xiaoxi_card_moqi.png') center
    center / contain no-repeat;
}
.message-match-card-avatar-wrapper {
  position: absolute;
  top: 16px;
  left: 237px;
  display: flex;
}
.message-match-card-avatar {
  box-sizing: border-box;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 2px #fff solid;
  overflow: hidden;
}
.message-match-card-avatar-2 {
  margin-left: 4px;
}
.message-match-card-avatar-img {
  display: flex;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.message-match-card-link {
  position: absolute;
  top: 10px;
  left: 34px;
  width: 24px;
  height: 24px;
}
.message-match-card-link-img {
  display: flex;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.message-match-card-title {
  position: absolute;
  top: 18px;
  left: 16px;
  width: 204px;
  font-weight: 500;
  font-size: 14px;
  color: #1f2626;
  line-height: 20px;
}
.message-match-card-subtitle {
  position: absolute;
  top: 76px;
  left: 16px;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  color: #464b4b;
  line-height: 17px;
}
.message-match-card-dot {
  box-sizing: border-box;
  margin-right: 8px;
  width: 6px;
  height: 6px;
  border: 2px solid var(--color-primary);
  border-radius: 50%;
  background-color: #fff;
}
</style>
