<template>
  <el-popover
    placement="bottom"
    trigger="click"
    popper-class="search-popper"
    ref="popover"
  >
    <div class="search-filter">
      <div class="search-filter-result">
        <SearchList
          v-if="filteredConversationList.length > 0"
          :conversationList="filteredConversationList"
          @select="handleSelect"
        />
        <div v-else>
          <el-empty description="无数据"></el-empty>
        </div>
      </div>
      <div class="search-filter-form">
        <el-form :model="model" ref="form" size="small" label-width="120px">
          <el-form-item label="用户昵称:" prop="nickname">
            <el-input
              v-model="model.nickname"
              clearable
              placeholder="用户昵称"
            />
          </el-form-item>
          <el-form-item label="皮雀id:" prop="pic_id">
            <el-input
              v-model="model.pic_id"
              clearable
              placeholder="皮雀id"
            />
          </el-form-item>
          <el-form-item label="用户身份:" prop="is_vip">
            <el-select
              v-model="model.is_vip"
              placeholder="用户身份"
              clearable
              style="width: 100%"
            >
              <el-option
                v-for="item in vipOptions"
                :key="item.key"
                :label="item.name"
                :value="item.key"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="最低财富等级:" prop="wealth_level">
            <el-input
              v-model="model.wealth_level"
              clearable
              placeholder="最低财富等级"
            />
          </el-form-item>
          <el-form-item label="最低魅力等级:" prop="charm_level">
            <el-input
              v-model="model.charm_level"
              clearable
              placeholder="最低魅力等级"
            />
          </el-form-item>
          <el-form-item label="是否在线:" prop="is_online">
            <el-select
              v-model="model.is_online"
              placeholder="是否在线"
              clearable
              style="width: 100%"
            >
              <el-option
                v-for="item in onlineOptions"
                :key="item.key"
                :label="item.name"
                :value="item.key"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <!-- <el-button
              class="filter-item"
              type="primary"
              icon="el-icon-search"
              @click="handleFilter"
            >
              搜索
            </el-button> -->
            <el-button
              class="filter-item"
              type="warning"
              icon="el-icon-refresh-right"
              @click="handleRefresh"
            >
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <template #reference>
      <slot></slot>
    </template>
  </el-popover>
</template>

<script>
import SearchList from '@/components/SearchList.vue';
import { throttle } from 'lodash-es';

export default {
  components: {
    SearchList,
  },
  props: {
    conversationList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      vipOptions: [
        { key: true, name: '会员用户' },
        { key: false, name: '普通用户' },
      ],
      onlineOptions: [
        { key: true, name: '在线' },
        { key: false, name: '不在线' },
      ],
      model: {
        nickname: undefined,
        pic_id: undefined,
        is_vip: undefined,
        wealth_level: undefined,
        charm_level: undefined,
        is_online: undefined,
      },
      throttledSearch: throttle(() => {
        return this.search();
      }, 500),
      filteredConversationList: [],
    };
  },
  watch: {
    model: {
      handler() {
        this.throttledSearch();
      },
      deep: true,
    },
  },
  methods: {
    doClose() {
      this.$refs.popover.doClose();
    },
    doShow() {
      this.$refs.popover.doShow();
    },
    search() {
      this.filteredConversationList = this.conversationList.filter(
        (conversation) => {
          const chatData = conversation.chatData;

          return Object.keys(this.model).every((key) => {
            const value = this.model[key];

            if (value === undefined || value === null || value === '') {
              return true;
            }

            switch (key) {
              case 'nickname':
                return chatData.nickname.includes(value);
              case 'pic_id':
                return String(chatData.pic_id).includes(value);
              case 'is_vip':
                return chatData.is_vip === value;
              case 'wealth_level':
                return chatData.wealth_level <= value;
              case 'charm_level':
                return chatData.charm_level <= value;
              case 'is_online':
                return chatData.is_online === value;
            }
          });
        },
      );
    },
    handleFilter() {},
    handleRefresh() {
      this.$refs.form.resetFields();
    },
    handleSelect(conversation) {
      this.$emit('select', conversation);
      this.doClose();
    },
  },
};
</script>

<style scoped lang="scss">
.search-filter {
  display: flex;
  width: 580px;
}
.search-filter-result {
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
  border-right: 1px solid #e9eaf1;
}
</style>
