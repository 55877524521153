<template>
  <div class="conversation-list" ref="scrollBox">
    <div
      v-for="conversation in conversationList"
      class="conversation-item"
      :class="{
        selected: selectedUserId === conversation.chatData.user_id,
      }"
      :key="conversation.conversationID"
      @click="handleSelect(conversation)"
    >
      <div
        v-if="showDelete"
        class="conversation-close"
        @click.stop.prevent="handleDelete(conversation)"
        src="@/images/home/contact/close.png"
      >
        <i class="el-icon-close"></i>
      </div>
      <el-badge
        :value="conversation.unreadCount"
        :max="99"
        :hidden="conversation.unreadCount === 0"
      >
        <el-image
          class="conversation-item-avatar"
          fit="cover"
          :src="conversation.chatData.head_img"
        />
      </el-badge>
      <div class="conversation-item-body">
        <div class="conversation-item-name">
          {{ conversation.chatData.nickname }}
        </div>
        <div class="conversation-item-msg">
          {{ getConversationShow(conversation) }}
        </div>
      </div>
      <div class="conversation-item-footer">
        {{ conversation.lastMessage.lastTime | calendar }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    conversationList: {
      type: Array,
      default: () => [],
    },
    selectedUserId: {
      type: Number,
    },
    robotId: {
      type: Number,
    },
    userPrefix: {
      type: String,
    },
    showDelete: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleSelect(conversation) {
      this.$emit('select', conversation);
    },
    handleDelete(conversation) {
      this.$emit('delete', conversation);
    },
    getConversationShow(conversation) {
      const message = conversation.lastMessage;
      if (message.isRevoked) {
        const isRobot = message.fromAccount === this.userPrefix + this.robotId;
        return `${isRobot ? '你' : '对方'}撤回了一条消息`;
      } else {
        return message.messageForShow;
      }
    },
    scrollToTop() {
      if (this.$refs.scrollBox) {
        this.$refs.scrollBox.scrollTop = 0
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.conversation-list {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
}
.conversation-item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 12px;
  cursor: pointer;

  &:hover,
  &.selected {
    background: var(--color-primary-100);
  }
}
.conversation-close {
  position: absolute;
  left: 0;
  top: 50%;
  z-index: 2;
  transform: translateY(-50%);
  display: none;
  justify-content: flex-start;
  align-items: center;
  width: 20px;
  height: 20px;
  font-size: 12px;
  color: #999;
  cursor: pointer;

  .conversation-item:hover & {
    display: flex;
  }

  &:hover {
    color: #000;
  }
}
.conversation-item-avatar {
  display: flex;
  width: 36px;
  height: 36px;
  border-radius: 6px;
}
.conversation-item-body {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding-left: 8px;
  justify-content: space-between;
}
.conversation-item-name {
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.conversation-item-msg {
  font-size: 12px;
  color: #999;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.conversation-item-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 12px;
  line-height: 16px;
  color: #bbb;
  white-space: nowrap;
}
</style>
