<template>
  <el-popover placement="top" trigger="click" popper-class="p-0" ref="popover">
    <div class="cascader-panel">
      <div class="cascader-menu">
        <div
          v-for="item in list"
          :key="item.id"
          class="cascader-menu-item"
          :class="{ selected: currentType && item.id === currentType.id }"
          @click="handleTypeClick(item)"
        >
          <div class="cascader-menu-item-label">{{ item.name }}</div>
          <div class="cascader-menu-item-arrow">
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
      </div>
      <div v-if="currentType" class="cascader-menu">
        <div
          v-for="item in currentType.script"
          :key="item.id"
          class="cascader-menu-item"
          @click="handleScriptClick(item)"
        >
          <div class="cascader-menu-item-label">{{ item.content }}</div>
          <div class="cascader-menu-item-arrow">
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
      </div>
    </div>
    <template #reference>
      <slot></slot>
    </template>
  </el-popover>
</template>

<script>
import { getScriptList, useScript } from '@/api/chat';

export default {
  data() {
    return {
      currentType: null,
      list: [],
    };
  },
  watch: {
    list: {
      handler() {
        if (this.list && this.list.length > 0) {
          this.currentType = this.list[0];
        }
      },
      immediate: true,
    },
  },
  created() {
    this.getScriptData();
  },
  methods: {
    getScriptData() {
      getScriptList().then((res) => {
        this.list = res.data;
      });
    },
    handleTypeClick(item) {
      this.currentType = item;
    },
    handleScriptClick(item) {
      this.$emit('select', item.content);
      this.doClose();
      useScript({ id: item.id });
    },
    doClose() {
      this.$refs.popover.doClose();
    },
    doShow() {
      this.$refs.popover.doShow();
    },
  },
};
</script>

<style scoped lang="scss">
.cascader-panel {
  display: flex;
  padding: 10px 0;
}
.cascader-menu {
  display: flex;
  flex-direction: column;
  width: 460px;
  height: 300px;
  overflow-x: hidden;
  overflow-y: auto;

  &:first-child {
    width: 160px;
  }

  &:not(:first-child) {
    border-left: 1px solid #e4e7ed;
  }
}
.cascader-menu-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
  height: 34px;
  flex: none;
  cursor: pointer;

  &.selected {
    color: var(--color-primary);
    font-weight: 700;
  }
  &:hover {
    background: var(--color-primary-100);
  }
}
.cascader-menu-item-label {
  flex: 1;
  padding: 0 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cascader-menu-item-arrow {
  font-size: 12px;
}
</style>
