import request from '@/utils/request';
// 聊天相关接口

// 获取马甲号列表
export function getRobotList(params) {
  return request({
    url: '/admin/robot/chat/robotList',
    method: 'get',
    params,
  });
}

// 登录马甲号
export function loginRobot(data) {
  return request({
    url: '/admin/robot/chat/login',
    method: 'post',
    data,
  });
}

// 退出马甲号
export function logoutRobot(data) {
  return request({
    url: '/admin/robot/chat/logout',
    method: 'post',
    data,
  });
}

//  获取IM配置信息
export function getRobotConfig(params) {
  return request({
    url: '/admin/robot/chat/config',
    method: 'get',
    params,
  });
}

// 马甲号聊天列表
export function getRobotChat(params) {
  return request({
    url: '/admin/robot/chat/robotChat',
    method: 'get',
    params,
  });
}

// 开始聊天
export function startRobot(data) {
  return request({
    url: '/admin/robot/chat/start',
    method: 'post',
    data,
  });
}

// 获取聊天详情
export function getRobotInfo(params) {
  return request({
    url: '/admin/robot/chat/info',
    method: 'get',
    params,
  });
}

// 获取用户聊天 今日数据 （聊天时长聊天数量
export function getRobotToday(params) {
  return request({
    url: '/admin/robot/chat/today',
    method: 'get',
    params,
  });
}

// 操作关注
export function actFollow(data) {
  return request({
    url: '/admin/robot/chat/actFollow',
    method: 'post',
    data,
  });
}

// 关闭聊天 一键释放用户
export function closeRobot(data) {
  return request({
    url: '/admin/robot/chat/close',
    method: 'post',
    data,
  });
}

// 解锁聊天 释放单个用户
export function unlockRobot(data) {
  return request({
    url: '/admin/robot/chat/unlock',
    method: 'post',
    data,
  });
}

// 话术列表
export function getScriptList(params) {
  return request({
    url: '/admin/script/scriptList',
    method: 'get',
    params,
  });
}

// 话术列表
export function useScript(data) {
  return request({
    url: '/admin/script/use',
    method: 'post',
    data,
  });
}

// 游戏 - 骰子 - 创建游戏
export function createDiceGame(data) {
  return request({
    url: '/admin/robot/game/dice/create',
    method: 'post',
    data,
  });
}

// 游戏 - 骰子 - 游戏详情
export function getDiceGameInfo(params) {
  return request({
    url: '/admin/robot/game/dice/info',
    method: 'get',
    params,
  });
}

// 游戏 - 骰子 - 获取题目
export function getDiceQa(params) {
  return request({
    url: '/admin/robot/game/dice/getQa',
    method: 'get',
    params,
  });
}

// 游戏 - 骰子 - 邀请游戏
export function inviteDiceGame(data) {
  return request({
    url: '/admin/robot/game/dice/invite',
    method: 'post',
    data,
  });
}

// 游戏 - 骰子 - 开始游戏
export function startDiceGame(data) {
  return request({
    url: '/admin/robot/game/dice/start',
    method: 'post',
    data,
  });
}

// 游戏 - 骰子 - 上报结果
export function reportDice(data) {
  return request({
    url: '/admin/robot/game/dice/report',
    method: 'post',
    data,
  });
}

// 游戏 - 猜拳 - 创建游戏
export function createCyclesGame(data) {
  return request({
    url: '/admin/robot/game/cycles/create',
    method: 'post',
    data,
  });
}

// 游戏 - 猜拳 - 游戏详情
export function getCyclesGameInfo(params) {
  return request({
    url: '/admin/robot/game/cycles/info',
    method: 'get',
    params,
  });
}

// 游戏 - 猜拳 - 开始游戏
export function startCyclesGame(data) {
  return request({
    url: '/admin/robot/game/cycles/start',
    method: 'post',
    data,
  });
}

// 游戏 - 猜拳 - 上报结果
export function reportCycles(data) {
  return request({
    url: '/admin/robot/game/cycles/report',
    method: 'post',
    data,
  });
}

// 游戏 - 猜拳 - 卡片选择
export function choiceCycles(data) {
  return request({
    url: '/admin/robot/game/cycles/choice',
    method: 'post',
    data,
  });
}

// 游戏 - 大冒险
export function sendDareMessage(data) {
  return request({
    url: '/admin/robot/game/dare',
    method: 'post',
    data,
  });
}

// 游戏 - 真心话
export function sendTruthMessage(data) {
  return request({
    url: '/admin/robot/game/truth',
    method: 'post',
    data,
  });
}

// 礼物 - 背包
export function getGiftBackpack(params) {
  return request({
    url: '/admin/user/gift/backpack',
    method: 'get',
    params,
  });
}

// 礼物 - 送礼物
export function giveGift(data) {
  return request({
    url: '/admin/user/gift/give',
    method: 'post',
    data,
  });
}

// 设置备注
export function setRemark(data) {
  return request({
    url: '/admin/robot/chat/setRemark',
    method: 'post',
    data,
  });
}

// 发送资源
export function sendMedia(data) {
  return request({
    url: '/admin/robot/media/send',
    method: 'post',
    data,
  });
}

// 已读
export function readMedia(data) {
  return request({
    url: '/admin/robot/media/read',
    method: 'post',
    data,
  });
}

// 资源详情
export function getMediaInfo(params) {
  return request({
    url: '/admin/robot/media/info',
    method: 'get',
    params,
  });
}

// 同意查看相册
export function agreeAlbum(data) {
  return request({
    url: '/admin/robot/album/agree',
    method: 'post',
    data,
  });
}

// 拒绝查看相册
export function refuseAlbum(data) {
  return request({
    url: '/admin/robot/album/refuse',
    method: 'post',
    data,
  });
}

// 隐私相册审核
export function operatePrivateAlbumApplyRecord(data) {
  return request({
    url: '/admin/user/privateAlbum/operateApplyRecord',
    method: 'post',
    data,
  });
}

// 发送聊天消息
export function sendCheck(data) {
  return request({
    url: '/admin/robot/sendMessage',
    method: 'post',
    data,
  });
}
