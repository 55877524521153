<template>
  <div
    class="snapchat-card"
    :class="[`flow-${flow}`, { destroyed }]"
    @click="handleClick"
  >
    <div v-if="showDestroyed">已自动销毁</div>
    <div v-else>
      私密信息会在{{ flow === 'out' ? '对方' : '你' }}
      <span class="view">查看后</span>
      自动销毁
    </div>
    <div class="snapchat-card-pic">
      <img v-if="showDestroyed" src="@/images/home/contact/img_xiaoxi_xiaohuixiangce.png" />
      <img v-else src="@/images/home/contact/img_xiaoxi_jiamixiangche.png" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    flow: {
      type: String,
      default: '',
    },
    destroyed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showDestroyed() {
      return this.destroyed && this.flow === 'in';
    },
  },
  methods: {
    handleClick() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
.snapchat-card {
  width: 137px;
  height: 139px;
  padding: 12px;
  background: #f5f9f9;
  border-radius: 4px 12px 12px 12px;

  &.flow-out {
    border-radius: 12px 4px 12px 12px;
  }

  font-weight: 400;
  font-size: 14px;
  color: #464b4b;
  line-height: 20px;

  .view {
    color: var(--color-primary);
  }

  &:not(.destroyed) {
    cursor: pointer;
  }
}

.snapchat-card-pic {
  display: flex;
  width: 113px;
  height: 67px;
  margin-top: 8px;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-radius: 8px;
}
</style>
