<template>
  <el-popover placement="top" trigger="click" popper-class="p-0" ref="popover">
    <Menu :list="list" @select="handleSelect"></Menu>
    <template #reference>
      <slot></slot>
    </template>
  </el-popover>
</template>

<script>
import Menu from '@/components/Menu.vue';
import { chooseFiles } from '@/utils/file';

export default {
  components: {
    Menu,
  },
  props: {
    isVip: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentType: null,
      list: [
        { key: 'general', icon: 'media', title: '普通图片/视频' },
        { key: 'snapchat', icon: 'snapchat', title: '阅后即焚图片/视频' },
      ],
    };
  },
  created() {},
  methods: {
    doClose() {
      this.$refs.popover.doClose();
    },
    doShow() {
      this.$refs.popover.doShow();
    },
    handleSelect(item) {
      if (item.key === 'snapchat' && !this.isVip) {
        this.$message.warning('当前马甲号不是VIP会员');
      } else {
        this.upload(item.key);
      }
      this.doClose();
    },
    upload(key) {
      chooseFiles({ accept: 'image/*,video/*' }).then(([file]) => {
        this.$emit('change', file, key);
      });
    },
  },
};
</script>

<style scoped lang="scss"></style>
