<template>
  <div class="message-game">
    <template v-if="type === 1">
      <img v-if="num === 1" src="@/images/home/game/ic_touzi_yi.png" />
      <img v-else-if="num === 2" src="@/images/home/game/ic_touzi_er.png" />
      <img v-else-if="num === 3" src="@/images/home/game/ic_touzi_san.png" />
      <img v-else-if="num === 4" src="@/images/home/game/ic_touzi_si.png" />
      <img v-else-if="num === 5" src="@/images/home/game/ic_touzi_wu.png" />
      <img v-else-if="num === 6" src="@/images/home/game/ic_touzi_liu.png" />
    </template>
    <template v-else-if="type === 2">
      <img v-if="num === 1" src="@/images/home/game/ic_caiquan_jiandao.png" />
      <img
        v-else-if="num === 2"
        src="@/images/home/game/ic_caiquan_shitou.png"
      />
      <img v-else-if="num === 3" src="@/images/home/game/ic_caiquan_bu.png" />
    </template>
  </div>
</template>

<script>
export default {
  props: {
    num: {
      type: Number,
      default: undefined,
    },
    type: {
      type: Number,
      default: undefined,
    },
  },
};
</script>

<style scoped lang="scss">
.message-cycles {
  img {
    display: block;
    width: 88px;
    height: 88px;
  }
}
</style>
